import React from 'react';
import './App.css';
import Menu from "../Menu/Menu";
import ListPart from "../ListPart/ListPart";
import MyKeyboard from '../Keyboard/Keyboard'
import Map from "../Map/Map";
import {IResidentDTO} from "../../Domains/IResidentDTO";
import {IActivityDTO} from "../../Domains/IActivityDTO";
import ResidentsFloor3 from "../ResidentsFloor/ResidentsFloor3";
import ResidentsFloor4 from "../ResidentsFloor/ResidentsFloor4";
import ResidentsFloor5 from "../ResidentsFloor/ResidentsFloor5";
import Activities from "../Activities/Activities";
import {getActivities, getAllResidents, getMaps} from "../../api/es_api";
import {IImageDTO} from "../../Domains/IImageDTO";
import {IActivityLocationDTO} from "../../Domains/IActivityLocationDTO";
import {IActivityXMLDTO} from "../../Domains/IActivityXMLDTO";
import { DigitalClock } from '../DigitalClock/DigitalClock';
import logo from '../../Images/54365.png'
import moment from 'moment';

interface AppProps{
}

interface AppState{
    menuItem:number;
    keyboard: any;
    input:string;
    showKeyboard:boolean;
    header: string;
    screenNumber: number;
    currentResident: IResidentDTO | null;
    currentActivity: IActivityDTO | null;
    showArrow: boolean,
    needSetDefaultImage: boolean;
    loading: boolean;
    residents: IResidentDTO[];
    activities: IActivityDTO[];
    images: IImageDTO[]
}

//direction - residents direction id, we have list of directions in ListPart.tsx file
//place - residents location (number or string)
//group - we need group for 6 screen, it indicate part of the map and it's used in map header
//imageId - we use it for saving residents map, we have list of images in Map.tsx file
//left/top positions - show position of resident on map

const Directions = [
    {id: 1, name: "Læger"},
    {id: 2, name: "Behandlere"},
    {id: 3, name: "Sygeplejeklinik"},
    {id: 4, name: "Ledere"},
    {id: 5, name: "Administration"},
    {id: 6, name: "Psykolog"},
    {id: 7, name: "Beober"},
    {id: 8, name: "Omsorgstandplejen"},
    {id: 9, name: "Genoptræning"},
    {id: 10, name: "Rehabilitering"},
    {id: 11, name: "Teknisk Service"}
]


//test her med at tilføje konference
const activityLocations: IActivityLocationDTO[] =[
    {name: "Store træningssal",floor: 1, left: 167, top: 215},
    {name: "Lille træningssal",floor: 1, left: 81, top: 294},
    {name: "Flexsal 2",floor: 1, left: 309, top: 557},
    {name: "Skoven",floor: 2, left: 75, top: 408},
    {name: "Spejlsalen",floor: 1, left: 309, top: 557},
    {name: "Køkkenalrummet",floor: 2, left: 298, top: 418},
    {name: "Tegnestuen",floor: 2, left: 75, top: 378},
    {name: "Loungen",floor: 2, left: 258, top: 584},
    {name: "Udsigten",floor: 2, left: 48, top: 584},
    {name: "Cafesalen", floor: 4, left: 73, top: 276},
    {name: "Konference", floor: 2, left: 480, top: 250}
]

/*const residents:IResidentDTO[] = [
    {id:2,name: "Nina Gregersen",direction:1, place:"2.021", floor:2,group:0,imageId:2,leftPosition:169,topPosition: 180},
    {id:1,name: "Lægerne Anbro og Soltau",direction:1, place:"2.025", floor:2,group:0,imageId:2,leftPosition:273,topPosition: 169},
    {id:1,name: "Fodterapeuter",direction:2, place:"2.037", floor:2,group:0,imageId:2,leftPosition:525,topPosition: 184},
    {id:3,name: "Sygeplejeklinik 1",direction:3, place:"2.052", floor:2,group:0,imageId:2,leftPosition:567,topPosition: 376},
    {id:4,name: "Sygeplejeklinik 2",direction:3, place:"2.054", floor:2,group:0,imageId:2,leftPosition:627,topPosition: 376},
    {id:6,name: "Leder af Sundhedshuset",direction:4, place:"2.040", floor:2,group:0,imageId:2,leftPosition:388,topPosition: 370},
    {id:5,name: "Faglig leder af Genoptræningen",direction:4, place:"1.023", floor:1,group:0,imageId:1,leftPosition:509,topPosition: 171},
    {id:7,name: "Leder Teknisk Service",direction:4, place:" 0.047", floor:0,group:0,imageId:4,leftPosition:335,topPosition: 80},
    {id:7,name: "Leder af Plejeboligområdet",direction:4, place:"1.017", floor:1,group:0,imageId:1,leftPosition:351,topPosition: 169},

    {id:8,name: "Genoptræningen",direction:5, place:"1.025", floor:1,group:0,imageId:1,leftPosition:562,topPosition: 171},
    {id:8,name: "Administration og lån af lokaler",direction:5, place:"2.048", floor:2,group:0,imageId:2,leftPosition:506,topPosition: 371},
    {id:8,name: "Sundhed og forebyggelseskonsulenter",direction:5, place:"2.044", floor:2,group:0,imageId:2,leftPosition:446,topPosition: 370},
    {id:8,name: "Administration af Plejeboligområdet",direction:5, place:"1.019", floor:1,group:0,imageId:1,leftPosition:403,topPosition: 167},

    {id:9,name: "Lisbet Sanders",direction:6, place:"2.056", floor:2,group:0,imageId:2,leftPosition:688,topPosition: 372},
    {id:9,name: "Lars Markussen",direction:6, place:"2.056", floor:2,group:0,imageId:2,leftPosition:688,topPosition: 372},
    {id:9,name: "Hope Cedercrantz",direction:6, place:"2.033", floor:2,group:0,imageId:2,leftPosition:473,topPosition: 184},

    {id:10,name: "Annelise Bjerregaard Olsen",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:260,topPosition: 542},
    {id:10,name: "Birthe Pedersen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:694,topPosition: 440},
    {id:10,name: "Bjarne Viggo Steen",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:158,topPosition: 426},
    {id:10,name: "Ellen Lunde Christensen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:162,topPosition: 300},
    {id:10,name: "Ellen Lykke Bülow",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:382,topPosition: 440},
    {id:10,name: "Hanne Riis Bjerredahl",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:586,topPosition: 440},
    {id:10,name: "Hanne Ingrid Nymann Jensen",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:158,topPosition: 348},
    {id:10,name: "Inger Mikkelsen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:669,topPosition: 300},
    {id:10,name: "Jens Erik Kaae",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:761,topPosition: 300},
    {id:10,name: "Jens Ove Hartmann",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:471,topPosition: 426},
    {id:10,name: "John Vestergaard Iversen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:470,topPosition: 300},
    {id:10,name: "Jørgen Karsum",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:487,topPosition: 545},
    {id:10,name: "Karen Mindested",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:162,topPosition: 279},
    {id:10,name: "Leif Kofoed",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:488,topPosition: 440},
    {id:10,name: "Lorraine Henriksen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:561,topPosition: 300},
    {id:10,name: "Quyen Quaach",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:269,topPosition: 300},
    {id:10,name: "Ruth Eriksen",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:409,topPosition: 426},
    {id:10,name: "Tove Elvira Nielsen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:360,topPosition: 300},
    {id:10,name: "Ulla Petersen",direction:7, place:"3.2", floor:3,group:2,imageId:7,leftPosition:274,topPosition: 440},
    {id:10,name: "Villy Lauridsen",direction:7, place:"3.1", floor:3,group:1,imageId:6,leftPosition:256,topPosition: 177},

    {id:10,name: "Alice Nielse",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:217,topPosition: 511},
    {id:10,name: "Alice Rylander Skov",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:158,topPosition: 348},
    {id:10,name: "Anne Grethe Jørgensen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:360,topPosition: 300},
    {id:10,name: "Annelise Jensen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:69,topPosition: 531},
    {id:10,name: "Bente Riisberg Jensen",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:409,topPosition: 426},
    {id:10,name: "Birgit Riis Klausen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:669,topPosition: 300},
    {id:10,name: "Birthe Gorell",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:488,topPosition: 440},
    {id:12,name: "Ellen Hansen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:591,topPosition: 162},
    {id:10,name: "Grete Steensen",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:471,topPosition: 426},
    {id:10,name: "Gunnar Nolsøe",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:385,topPosition: 545},
    {id:10,name: "Hans Gunnadal",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:279,topPosition: 162},
    {id:10,name: "Hans Preben Nielsen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:217,topPosition: 511},
    {id:10,name: "Ingelis Rasmussen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:274,topPosition: 440},
    {id:10,name: "Ingerita Rømø Nielsen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:561,topPosition: 300},
    {id:10,name: "Jan Arne Pedersen",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:158,topPosition: 426},
    {id:10,name: "Karen Margrethe Rosenqvist",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:470,topPosition: 300},
    {id:10,name: "Kenneth Peter Broo Sørensen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:694,topPosition: 440},
    {id:10,name: "Kim Christian Nyhus",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:256,topPosition: 177},
    {id:10,name: "Ledig",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:260,topPosition: 542},
    {id:10,name: "Ledig",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:269,topPosition: 300},
    {id:10,name: "Leis Morsing",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:586,topPosition: 440},
    {id:10,name: "Leo Gregers Kregers",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:108,topPosition: 416},
    {id:10,name: "Merete Nielsen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:403,topPosition: 160},
    {id:10,name: "Michael Nielsen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:497,topPosition: 169},
    {id:10,name: "Rita Thomsen",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:162,topPosition: 300},
    {id:10,name: "Rita Doris Sloth",direction:7, place:"4.5", floor:4,group:5,imageId:7,leftPosition:761,topPosition: 300},
    {id:10,name: "Søren Knud Larsen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:314,topPosition: 154},
    {id:10,name: "Torben Knudsen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:153,topPosition: 240},
    {id:10,name: "Torben Jørgensen",direction:7, place:"4.3", floor:4,group:3,imageId:5,leftPosition:166,topPosition: 173},
    {id:10,name: "Tove Elfinde Johansen",direction:7, place:"4.4", floor:4,group:4,imageId:6,leftPosition:487,topPosition: 545},

    {id:11,name: "Agneta Kallesø",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:162,topPosition: 279},
    {id:11,name: "Anni Jensen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:69,topPosition: 531},
    {id:11,name: "Bent Jensen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:470,topPosition: 300},
    {id:11,name: "Bent Sigurd Pedersen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:694,topPosition: 440},
    {id:11,name: "Birth Lunau",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:497,topPosition: 159},
    {id:11,name: "Edel Agnes Jancy Vest",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:256,topPosition: 177},
    {id:11,name: "Else Winther-Jørgensen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:276,topPosition: 440},
    {id:11,name: "Finn Richard Eli Olsen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:166,topPosition: 173},
    {id:11,name: "Ib Eilertsen",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:260,topPosition: 542},
    {id:11,name: "Inge Bente Møller",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:586,topPosition: 440},
    {id:11,name: "Irma Kirstine Nielsen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:217,topPosition: 511},
    {id:11,name: "Jose Rain",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:162,topPosition: 300},
    {id:11,name: "Jørgen Hougaard",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:385,topPosition: 545},
    {id:11,name: "Jørgen Andersen",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:158,topPosition: 426},
    {id:11,name: "Kaj Ove Larsen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:314,topPosition: 154},
    {id:11,name: "Karen Rigmor Hansen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:153,topPosition: 240},
    {id:11,name: "Keld Hansen",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:108,topPosition: 416},
    {id:11,name: "Kirsten Annette Frederiksen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:360,topPosition: 300},
    {id:11,name: "Kirsten Dotrhea Blaabjærg",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:561,topPosition: 300},
    {id:11,name: "Lilian Birthe Elmegaard Dühring",direction:7, place:"5.6", floor:5,group:6,imageId:5,leftPosition:591,topPosition: 162},
    {id:11,name: "Mahi Berk",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:269,topPosition: 300},
    {id:11,name: "Manzoor Begum Gill",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:488,topPosition: 440},
    {id:11,name: "Maria Susanne Sørensen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:761,topPosition: 300},
    {id:11,name: "Martin Meyer Hansen",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:487,topPosition: 545},
    {id:11,name: "Mary Nilsen",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:158,topPosition: 348},
    {id:11,name: "Oda Kristensen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:382,topPosition: 440},
    {id:11,name: "Poul Larsen",direction:7, place:"5.7", floor:5,group:7,imageId:6,leftPosition:409,topPosition: 426},
    {id:11,name: "Sonja Henrica Simonsen",direction:7, place:"5.8", floor:5,group:8,imageId:7,leftPosition:669,topPosition: 300},
]

const activityItems: IActivityDTO[] = [
    /!*{id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},



    {id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:1, time:'11.00-12.00',name:"TKA- knæhold",location:"Store træningssal",floor: 1,imageId:1,leftPosition:167,topPosition: 215},
    {id:2, time:'11.00-12.00',name:"TKA- knæhold",location:"Lille træningssal",floor: 1,imageId:1,leftPosition:81,topPosition: 294},
    {id:3, time:'12.00-14.30',name:"Lungehold",location:"Flexsal 2",floor: 1,imageId:1,leftPosition:309,topPosition: 557},
    {id:4, time:'15.45-18.15',name:"Hjertehold",location:"Skoven",floor: 2,imageId:2,leftPosition:75,topPosition: 408},
    {id:5, time:'18.00-19.30',name:"Body SDA",location:"Spejlsalen",floor: 1,imageId:1,leftPosition:309,topPosition: 557},*!/
]

const shortResidents:IShortResidentDTO[] = [
    {name:"Annelise Bjerregaard Olsen",place:"10.3.3"},
    {name:"Bjarne Viggo Steen",place:"10.3.4"},
    {name:"Hanne Ingrid Nymann Jensen",place:"10.3.5"},
    {name:"Karen Mindested",place:"10.3.6"},
    {name:"Villy Lauridsen",place:"10.3.7"},
    {name:"Ruth Eriksen",place:"10.3.8"},
    {name:"Ledig",place:"10.3.9"},
    {name:"Tove Elvira Nielsen",place:"10.3.12"},
    {name:"John Vestergaard Iversen",place:"10.3.13"},
    {name:"Lorraine Henriksen",place:"10.3.14"},
    {name:"Inger Mikkelsen",place:"10.3.15"},
    {name:"Jens Erik Kaae",place:"10.3.16"},
    {name:"Birthe Pedersen",place:"10.3.17"},
    {name:"Hanne Riis Bjerredahl",place:"10.3.18"},
    {name:"Leif Kofoed",place:"10.3.19"},
    {name:"Ellen Lykke Bülow",place:"10.3.20"},
    {name:"Ulla Petersen",place:"10.3.21"},
    {name:"Annelise Jensen",place:"4.4.1"},
    {name:"Leo Gregers Kregers",place:"4.4.2"},
    {name:"Torben Knudsen",place:"4.4.3"},
    {name:"Torben Jørgensen",place:"4.4.4"},
    {name:"Søren Knud Larsen",place:"4.4.5"},
    {name:"Merete Nielsen",place:"4.4.6"},
    {name:"Michael Nielsen",place:"4.4.7"},
    {name:"Ellen Hansen",place:"4.4.8"},
    {name:"Hans Preben Nielsen",place:"4.4.9"},
    {name:"Tove Elfinde Johansen",place:"10.4.1"},
    {name:"Gunnar Nolsøe",place:"10.4.2"},
    {name:"Torsten Junge",place:"10.4.3"},
    {name:"Jan Arne Pedersen",place:"10.4.4"},
    {name:"Alice Rylander Skov",place:"10.4.5"},
    {name:"Hans Gunnadal",place:"10.4.6"},
    {name:"Kim Christian Nyhus",place:"10.4.7"},
    {name:"Bente Riisberg Jensen",place:"10.4.8"},
    {name:"Grete Steensen",place:"10.4.9"},
    {name:"Rita Thomsen",place:"10.4.10"},
    {name:"Birte Elsebeth Andersen",place:"10.4.11"},
    {name:"Anne Grethe Jørgensen",place:"10.4.12"},
    {name:"Karen Margrethe Rosenqvist",place:"10.4.13"},
    {name:"Ingerita Rømø Nielsen",place:"10.4.14"},
    {name:"Birgit Riis Klausen",place:"10.4.15"},
    {name:"Rita Doris Sloth",place:"10.4.16"},
    {name:"Kenneth Broo Sørensen",place:"10.4.17"},
    {name:"Leis Morsing",place:"10.4.18"},
    {name:"Birthe Gorell",place:"10.4.19"},
    {name:"Pia Ulsing",place:"10.4.20"},
    {name:"Ingelis Rasmussen",place:"10.4.21"},
    {name:"Anni Jensen",place:"4.5.1"},
    {name:"Keld Hansen",place:"4.5.2"},
    {name:"Karen Rigmor Hansen",place:"4.5.3"},
    {name:"Finn Richard Eli Olsen",place:"4.5.4"},
    {name:"Kaj Ove Larsen",place:"4.5.5"},
    {name:"Rita Olsen",place:"4.5.6"},
    {name:"Birth Lunau",place:"4.5.7"},
    {name:"Lilian Birthe Elmegaard Dühring",place:"4.5.8"},
    {name:"Irma Kirstine Nielsen",place:"4.5.9"},
    {name:"Martin Meyer Hansen",place:"10.5.1"},
    {name:"Jørgen Hougaard",place:"10.5.2"},
    {name:"Ib Eilertsen",place:"10.5.3"},
    {name:"Jørgen Andersen",place:"10.5.4"},
    {name:"Mary Nilsen",place:"10.5.5"},
    {name:"Ledig",place:"10.5.6"},
    {name:"Edel Agnes Jancy Vest",place:"10.5.7"},
    {name:"Poul Larsen",place:"10.5.8"},
    {name:"Hans Jørgensen",place:"10.5.9"},
    {name:"Jose Rain",place:"10.5.10"},
    {name:"Mahi Berk",place:"10.5.11"},
    {name:"Kirsten Annette Frederiksen",place:"10.5.12"},
    {name:"Bent Jensen",place:"10.5.13"},
    {name:"Kirsten Dotrhea Blaabjærg",place:"10.5.14"},
    {name:"Sonja Henrica Simonsen",place:"10.5.15"},
    {name:"Maria Susanne Sørensen",place:"10.5.16"},
    {name:"Bent Sigurd Pedersen",place:"10.5.17"},
    {name:"Inge Bente Møller",place:"10.5.18"},
    {name:"Manzoor Begum Gill",place:"10.5.19"},
    {name:"Oda Kristensen",place:"10.5.20"},
    {name:"Else Winther-Jørgensen",place:"10.5.21"}
]*/


class App extends React.Component<AppProps, AppState>{

    constructor(props: any) {
        super(props);

        this.timeout = setTimeout(() => { }, 2000)
    }

    state = {
        menuItem: 0,
        keyboard: null,
        input:"",
        showKeyboard: false,
        header: "Mødeoversigt",
        currentActivity: null,
        currentResident: null,
        showArrow: false,
        screenNumber: 1,
        needSetDefaultImage: false,
        loading: true,
        residents: [] as IResidentDTO[],
        activities: [],
        images:[]
    };

    private timeout: ReturnType<typeof setTimeout>;

    componentWillMount() {
        this.setScreenNumber()
    }

    async componentDidMount() {

        const dataPerson = await getAllResidents()

        const dataMap = await getMaps()

        var bookings = await getActivities()
        var sorted_bookings = bookings.sort((a, b) => {
            return a.start > b.start ? 1 : -1
        });

        let xmlActivities: IActivityXMLDTO[] = []
        sorted_bookings.forEach(booking => {
            let xmlActivity: IActivityXMLDTO = {
                bookingId: booking.id,
                changingRoomAway: '',
                changingRoomHome: '',
                date: moment(booking.start).format('yyyy-MM-dd'),
                description: booking.description ? booking.description : '',
                headline: booking.title ? booking.title : (booking.description ? booking.description : ''),
                room: booking.calendar.name,
                tenant: 'Sundhedshuset',
                text: '',
                timeFrom: moment(booking.start).format('HH:mm'),
                timeTo: moment(booking.end).format('HH:mm'),
                group: ''
            }
            xmlActivities.push(xmlActivity)
        })

        //LEo test activity
        console.log(xmlActivities);

        
        //xmlActivities.push({ date: "2022-08-29", timeFrom: "13:00", timeTo: "18:00", room: "Konferencen", text: "test", tenant: "KOL foreningen", changingRoomHome: "", changingRoomAway: "", group: "Sundhedshuset samlet", bookingId: "16555", headline: "TEEEEEEEEEEEST", description: "testets" } as IActivityXMLDTO)

        xmlActivities = this.activitiesFilter(xmlActivities)

        const residents = dataPerson.map((x)=>({id:0, name: x.firstName + " " + x.lastName ,direction: this.changePositionToDirection(x.position),
            floor:x.floor, place: x.name, imageId: x.mapId, leftPosition: x.locationX, topPosition:x.locationY,group: x.group }) as IResidentDTO)

        const images = dataMap.map((x)=>({id:x.id, image: x.base64Image}) as IImageDTO)

        const activities = xmlActivities.map((x)=>({time: x.timeFrom+" - "+x.timeTo, name: x.headline, location:x.room,
            floor:this.getFloorPositionForActivity(x.room), imageId:this.getFloorPositionForActivity(x.room), leftPosition:this.getLeftPositionForActivity(x.room),
            topPosition: this.getTopPositionForActivity(x.room)
        }) as IActivityDTO)

        this.setState({residents})
        this.setState({images})
        this.setState({activities})
        this.setState({loading:false})
    }

    activitiesFilter(activities: IActivityXMLDTO[]){
        const date = new Date()
        const hour = date.getHours()
        const minutes = date.getMinutes()
        let result = [] as IActivityXMLDTO[]

        for(let i=0; i<activities.length;i++){
            /*const timeFrom = parseInt(activities[i].timeFrom)*/
            const timeTo = parseInt(activities[i].timeTo)
            const minutesTo = parseInt(activities[i].timeTo.substr(3,2))
            if( (timeTo>hour || (timeTo===hour && minutesTo>minutes) ) && timeTo !== 24) {
                result.push(activities[i])
            }
        }

        return result
    }

    changePositionToDirection(position: string){
        switch (position) {
            /*case "Læger":
                return 1;
            case "Behandlere":
                return 2;
            case "Sygeplejeklinik":
                return 3;
            case "Ledere":
                return 4;
            case "Administration":
                return 5;
            case "Psykolog":
                return 6;
            default:
                return 7;*/
            case "1":
                return 1;
            case "2":
                return 2;
            case "3":
                return 3;
            case "4":
                return 4;
            case "5":
                return 5;
            case "6":
                return 6;
            case "8":
                return 8;
            case "9":
                return 9;
            case "10":
                return 10;
            case "11":
                return 11;
            default:
                return 7;
        }
    }

    getFloorPositionForActivity(name: string){
        const result = activityLocations.filter(x=>x.name === name).map(x=>x.floor)

        if(result.length>0) return result[0]

        return 1
    }

    getLeftPositionForActivity(name: string){
        const result = activityLocations.filter(x=>x.name === name).map(x=>x.left)

        if(result.length>0) return result[0]

        return 167
    }

    getTopPositionForActivity(name: string){
        const result = activityLocations.filter(x=>x.name === name).map(x=>x.top)

        if(result.length>0) return result[0]

        return 215
    }

    getGroupStringFromLocation(location: string){

        let result = ''
        let isReady = false

        for (var i = 0; i < location.length; i++) {
            if(location[i]==='.'){
                i++;
                while(location[i]!=='.'){
                    result+=location[i]
                }
                isReady = true;
            }
            if(isReady) break
        }
    }

    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
        clearTimeout(this.timeout);
        this.setScreenNumber()
        this.timeout = setTimeout(()=> this.setDefault(),20000)
    }

    handleSetDefaultImage = () =>{
        this.setState({needSetDefaultImage: false})
    }

    setDefault = () =>{
        this.handleChangeOfMenuItem(0)
        this.setState({currentActivity:null})
        this.setState({currentResident:null})
        this.setState({showKeyboard:false})
        this.setState({needSetDefaultImage: true})
    }

    handleChangeOfMenuItem = (id: number) =>{
        if(this.state.menuItem===id)
            this.setState({menuItem: 0})
        else {
            if(this.state.screenNumber === 5 || this.state.screenNumber === 6) {
                if(id >= 4 && id <= 6) {
                    this.setState({menuItem: 0})
                    this.setState({showArrow: true})
                    setTimeout(() => {
                        this.setState({menuItem: id})
                        this.setState({showArrow: false})
                    }, 2000)
                } else
                    this.setState({menuItem: id})
            } else
                this.setState({menuItem: id})
        }
    }

    handleInputChange = (input: string)=>this.setState({input:input});

    handleShowKeyboard = (show: boolean) =>this.setState({showKeyboard:show});

    handleChangeHeader = (header: string) => this.setState({header:header})

    handleResidentChange = (resident: IResidentDTO) =>{
        if((this.state.screenNumber === 5 || this.state.screenNumber === 6) && resident.name !== 'Leder Teknisk Service ') {
            this.setState({currentResident:null});
            this.setState({showArrow: true})
            setTimeout(() => {
                this.setState({currentResident:resident});
                this.setState({currentActivity: null});
                this.setState({showArrow: false})
            }, 2000)
        } else {
            this.setState({currentResident:resident});
            this.setState({currentActivity: null});   
        }
    }

    handleActivityChange = (activity: IActivityDTO | null)=>{
        if((this.state.screenNumber === 5 || this.state.screenNumber === 6) && activity) {
            this.setState({currentActivity:null});
            this.setState({showArrow: true})
            setTimeout(() => {
                this.setState({currentActivity: activity});
                this.setState({currentResident:null});
                this.setState({showArrow: false})
            }, 2000)
        } else {
            this.setState({currentActivity: activity});
            this.setState({currentResident:null});  
        }
    }

    showAdditionalScreens = () =>{
        if( this.state.screenNumber === 7 ||
            this.state.screenNumber === 8 ||
            this.state.screenNumber=== 9 ||
            this.state.screenNumber === 10 ||
            this.state.screenNumber === 11)
            return true;
        return false;
    }

    setScreenNumber = () =>{

        let screenNumber = 1;
        const location = window.location.pathname

        if(location==='/1') screenNumber = 1
        if(location==='/2') screenNumber = 2
        if(location==='/3') screenNumber = 3
        if(location==='/4') screenNumber = 4
        if(location==='/5') screenNumber = 5
        if(location==='/6') screenNumber = 6
        if(location==='/7') screenNumber = 8
        if(location==='/8') screenNumber = 9
        if(location==='/9') screenNumber = 10
        if(location==='/10') screenNumber = 11
        if(location==='/11') screenNumber = 1

        if(this.state.screenNumber!==screenNumber)
            this.setState({screenNumber:screenNumber})
    }

    render() {
        const showAdditionalScreens = this.showAdditionalScreens()
        const {loading, currentResident, menuItem, screenNumber } = this.state



        const show3Floor = this.state.screenNumber === 11
        const show4Floor = this.state.screenNumber === 10
        const show5Floor = this.state.screenNumber === 9
        const showActivities = this.state.screenNumber === 8

        if(!loading) {

            const shortResidents3 = this.state.residents.filter(x=>x.place.includes('.3.'))
            const shortResidents4 = this.state.residents.filter(x=>x.place.includes('.4.'))
            const shortResidents5 = this.state.residents.filter(x=>x.place.includes('.5.'))
            const activitiesForAdditionalScreen = this.state.activities.slice(0,5)
            return (
                <div style={{overflow:"hidden", height: "1080px"}}>
                    {(this.state.screenNumber > 0 && this.state.screenNumber < 7) &&
                        <div className="logo-header">
                            <div className="left-part">
                                <img src={logo} alt="logo" />
                                <div className="header-text">
                                    <p className="big-text">{this.state.screenNumber === 6 ? "Albertshøj & Rehabilitering" : "Sundhedshuset"}</p>
                                    <label>{this.state.screenNumber === 6 ? "Find beboer på Albertshøj i oversigten til venstre" : "Find personale i menuen til venstre"}</label>
                                </div>
                            </div>
                            <div className="right-part">
                                <DigitalClock />
                            </div>
                        </div>
                    }
                    {!showAdditionalScreens ? <div className={this.state.screenNumber === 6 ? "App-additional" : "App"}>
                        <Menu
                            screenNumber={this.state.screenNumber}
                            handleChangeOfMenuItem={this.handleChangeOfMenuItem}
                            handleChangeHeader={this.handleChangeHeader}
                            handleActivityChange={this.handleActivityChange}
                            menuItem={this.state.menuItem}
                        />
                        {(screenNumber !== 6 || 
                          (screenNumber === 6 && currentResident === null) ||
                          (screenNumber === 6 && currentResident !== null && (menuItem !== 11 && menuItem !== 12 && menuItem !== 13 && menuItem !== 0))) &&
                            <ListPart
                                menuItem={this.state.menuItem}
                                input={this.state.input}
                                handleShowKeyboard={this.handleShowKeyboard}
                                header={this.state.header}
                                handleActivityChange={this.handleActivityChange}
                                handleResidentChange={this.handleResidentChange}
                                activities={this.state.activities}
                                residents={this.state.residents}
                                showAdditionalScreens={showAdditionalScreens}
                                screenNumber={this.state.screenNumber}
                            />
                        }
                        <Map
                            menuItem={this.state.menuItem}
                            currentActivity={this.state.currentActivity}
                            currentResident={this.state.currentResident}
                            screenNumber={this.state.screenNumber}
                            needSetDefaultImage={this.state.needSetDefaultImage}
                            handleSetDefaultImage={this.handleSetDefaultImage}
                            images={this.state.images}
                            showArrow={this.state.showArrow}
                        />
                    </div> : ''}

                    {show3Floor ? <ResidentsFloor3 residents={shortResidents3}/> : ""}
                    {show4Floor ? <ResidentsFloor4 residents={shortResidents4}/> : ""}
                    {show5Floor ? <ResidentsFloor5 residents={shortResidents5}/> : ""}
                    {showActivities ? <Activities activities={activitiesForAdditionalScreen}/> : ""}

                    <div className={this.state.showKeyboard ? "My-keyboard-container" : "Hidden"}>
                        <MyKeyboard
                            handleInputChanges={this.handleInputChange}
                            handleShowKeyboard={this.handleShowKeyboard}
                            input={this.state.input}
                        />
                    </div>
                </div>
            );
        }
        else return (<div>loading...</div>)
    }
}

export default App;
