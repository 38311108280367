import React from "react";
import './Map.css';
import Stuen from           "../../Images/Stuen90.jpg"
import Stuenrot180 from     "../../Images/Stuen90_rot180.jpg"
import Stuenrot270 from     "../../Images/Stuen90_rot270.jpg"
import Sal1 from            "../../Images/1sal_cut.jpg"
import Sal1rot180 from      "../../Images/1sal_cut_rot180.jpg"
import Sal1rot270 from      "../../Images/1sal_cut_rot270.jpg"
import Sal2 from            "../../Images/2sal_cut.jpg"
import Sal2rot180 from      "../../Images/2sal_cut_rot180.jpg"
import Sal2rot270 from      "../../Images/2sal_cut_rot270.jpg"
import Sal3 from            "../../Images/Kort_albertshøj_edit_3sal_1920x1080_crop.jpg"
import Sal3rot180 from      "../../Images/Kort_albertshøj_edit_3sal_1920x1080_crop_rot180.jpg"
import Sal3rot270 from      "../../Images/Kort_albertshøj_edit_3sal_1920x1080_crop_rot270.jpg"
import Part1 from           "../../Images/Kort_albertshøj_edit_3sal_crop_1.jpg"   //NOTE (aske): "Parts" are zoomed in versions of floor 3
import Part1rot180 from     "../../Images/Kort_albertshøj_edit_3sal_crop_1_rot180.jpg"
import Part1rot270 from     "../../Images/Kort_albertshøj_edit_3sal_crop_1_rot270.jpg"
import Part2 from           "../../Images/Kort_albertshøj_edit_3sal_crop_2.jpg"
import Part2rot180 from     "../../Images/Kort_albertshøj_edit_3sal_crop_2_rot180.jpg"
import Part2rot270 from     "../../Images/Kort_albertshøj_edit_3sal_crop_2_rot270.jpg"
import Part3 from           "../../Images/Kort_albertshøj_edit_3sal_crop_3_1.jpg"
import Part3rot180 from     "../../Images/Kort_albertshøj_edit_3sal_crop_3_1_rot180.jpg"
import Part3rot270 from     "../../Images/Kort_albertshøj_edit_3sal_crop_3_1_rot270.jpg"
import {IResidentDTO} from  "../../Domains/IResidentDTO";
import {IActivityDTO} from  "../../Domains/IActivityDTO";
import {IImageDTO} from     "../../Domains/IImageDTO";
import Route from           "../Route/Route";
import Arrow from           "../../Images/Arrow.png"
import Modal from 'react-modal';
import { IPositionDTO } from "../../Domains/IPositionDTO";

interface MapProps{
    menuItem: number;
    currentResident: IResidentDTO | null;
    currentActivity: IActivityDTO | null;
    screenNumber: number;
    needSetDefaultImage: boolean;
    handleSetDefaultImage: ()=>void;
    images: IImageDTO[],
    showArrow: boolean
}

interface MapState{
    image: any;
    imageSize:{
        width:number,
        height: number
    };
    screenPosition: IPositionDTO;
    //{
    //    left:number,
    //    top: number
    //}
    pointerPosition: IPositionDTO;
    //{
    //    left:number,
    //    top: number
    //};
    header: string;
}

const modalStyles = {
    content: {
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        backgroundColor: 'white',
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100000
    },
};

const Images = [
    {id: 1, image: Sal1},
    {id: 2, image: Sal2},
    {id: 3, image: Sal3},
    {id: 4, image: Stuen},
    {id: 5, image: Part1},
    {id: 6, image: Part2},
    {id: 7, image: Part3},
    { id: 8, image: "" }, //NOTE(aske): This seems to be for broken locations, to prevent crashes

    { id: 9, image: Sal1rot180 }, //8+id
    { id: 10, image: Sal2rot180 },
    { id: 11, image: Sal3rot180 },
    { id: 12, image: Stuenrot180 },
    { id: 13, image: Part1rot180 },
    { id: 14, image: Part2rot180 },
    { id: 15, image: Part3rot180 },
    { id: 16, image: "" },

    { id: 17, image: Sal1rot270 },//16+id
    { id: 18, image: Sal2rot270 },
    { id: 19, image: Sal3rot270 },
    { id: 20, image: Stuenrot270 },
    { id: 21, image: Part1rot270 },
    { id: 22, image: Part2rot270 },
    { id: 23, image: Part3rot270 },
    { id: 24, image: "" },
]

const DefaultImages = [
    {screenNumber:1,url:Sal2rot270},
    {screenNumber:2,url:Sal2rot180},
    {screenNumber:3,url:Sal1rot270},
    {screenNumber:4,url:Sal1rot180},
    {screenNumber:5,url:Stuen},
]

const DefaultPositions = [ //"You are here" locations - unrotated values:
    { screenNumber: 1, left: 560, top: 590 }, //left: 158, top: 564
    { screenNumber: 2, left: 110, top: 555 }, //left: 646, top: 226
    { screenNumber: 3, left: 560, top: 590 }, //left: 158, top: 564
    { screenNumber: 4, left: 110, top: 555 }, //left: 646, top: 226
    { screenNumber: 5, left: 450, top: 465 },
    { screenNumber: 6, left: 450, top: 465 }
]

const DirectionsForLocationsWithoutSublocations = [
    {menuId: 4, imageId: 2, floor: 2, leftPosition: 572, topPosition:193},
    {menuId: 5, imageId: 1, floor: 1, leftPosition: 630, topPosition:386},
    {menuId: 6, imageId: 3, floor: 3, leftPosition: 53, topPosition:363},
    {menuId: 9, imageId: 4, floor: 0, leftPosition: 600, topPosition:350}
]

class Map extends React.PureComponent<MapProps, MapState>{

    state:MapState = {
        image: null,
        imageSize:{
            width: 770,
            height: 810
        },
        screenPosition:{
            left: 158,
            top: 564
        },
        pointerPosition:{
            left: 0,
            top: 0
        },
        header:"",
    };

    componentDidMount() {
        this.chooseDefaultImage()
    }

    componentDidUpdate(prevProps: Readonly<MapProps>, prevState: Readonly<MapState>, snapshot?: any) {

        if(this.props.needSetDefaultImage ||
            (this.props.menuItem===0
            && this.props.menuItem!==prevProps.menuItem
            && !this.props.currentResident
            && !this.props.currentActivity
            )
        ) {
            this.chooseDefaultImage()
            this.props.handleSetDefaultImage()
        }

        if(DirectionsForLocationsWithoutSublocations.map(x=>x.menuId).includes(this.props.menuItem)
            && !this.props.currentActivity
            && !this.props.currentResident
        ) {
            this.setImageForLocationsWithoutSublocations()
        }
        if((this.props.currentResident!==prevProps.currentResident || this.props.currentActivity!==prevProps.currentActivity)
            && (this.props.currentActivity || this.props.currentResident)
        ) {
            this.checkImageChange()
        }

        this.setMapSize()
        this.setHeader()
    }

    getRotatedImageIfNeeded(screenNumber : number, imageId : number) : number {
        if (screenNumber === 1 || screenNumber === 3) { //270 deg
            return 16 + imageId;
        }
        if (screenNumber === 2 || screenNumber === 4) { //180 deg
            return 8 + imageId;
        }
        return imageId;
    }

    getRotatedPinPositionIfNeeded(screenNumber: number, position: IPositionDTO): IPositionDTO {
        if (position.left <= 0 || position.top <= 0) {
            return position; //Invalid position should stay invalid
        }
        var rotationAngleDegrees = 0;
        if (screenNumber === 1 || screenNumber === 3) {
            rotationAngleDegrees = 270;
        }
        else if (screenNumber === 2 || screenNumber === 4) {
            rotationAngleDegrees = 180;
        }
        if (rotationAngleDegrees === 0) {
           // console.log("No rotation (screen " + screenNumber + "), pin: (" + position.left + "," + position.top + ")");
            return position;
        }
        
        //console.log(rotationAngleDegrees + " rotation (screen " + screenNumber + ")");
        var result: IPositionDTO = { left: position.left, top: position.top };
        result.left += 30; //Offset 1/8 width because these coordinates doesn't correspond to the tip of the pin
        result.top += 15; //Offset 1/4 height
        const pivotPoint = rotationAngleDegrees % 180 === 0 ? { left: 385, top: 405 } : { left: 385, top: 385 };
        const sinAngle = (Math.sin(rotationAngleDegrees * 0.0174532925)); 
        const cosAngle = (Math.cos(rotationAngleDegrees * 0.0174532925));
        //console.log("sin: " + sinAngle + ", cos: " + cosAngle);
        //console.log("result pre-calc: (" + result.left + "," + result.top + ")");
        //console.log("pivot: (" + pivotPoint.left + "," + pivotPoint.top + ")");
        result.left -= pivotPoint.left;
        result.top -= pivotPoint.top;
        //console.log("translated: (" + result.left + "," + result.top + ")");
        let rotatedX = ((result.left * cosAngle) - (result.top * sinAngle));
        let rotatedY = ((result.left * sinAngle) + (result.top * cosAngle));
        //console.log("pretranslate to origin: (" + (rotatedX) + "," + (rotatedY) + ")");
        result.left = rotatedX + pivotPoint.left;
        result.top = rotatedY + pivotPoint.top;
        //console.log("rotated (" + position.left + "," + position.top + ") to (" + result.left + "," + result.top + ")");

        //NOTE(aske): top has 15 additional offset because many pins are located above text,
        //so this is just a hacky fix to not have the pin in front of the text, primarily in 180 rotation
        result.left -= 30;
        result.top -= 30;
        return result;
    }

    setMapSize=()=>{

        if(this.props.screenNumber===6 && (this.props.menuItem === 11 || this.props.menuItem === 12 || this.props.menuItem === 13)){
            if(this.state.imageSize.width!==910) {
                this.setState({imageSize: {width: 910, height: 810}})
            }
        }
        else if (this.props.screenNumber === 1 || this.props.screenNumber === 3) {
            //NOTE (aske): changing these dimensions will mess with pin and route locations
            if (this.state.imageSize.height !== 770) {
                this.setState({ imageSize: { width: 810, height: 770 } })
            }
        }
        else {
            if (this.state.imageSize.width !== 770 && this.state.imageSize.height !== 810) {
                this.setState({imageSize: {width: 770, height: 810}})
            }
        }
    }

    setImageForLocationsWithoutSublocations=()=>{
        const direction = DirectionsForLocationsWithoutSublocations.filter(x => x.menuId === this.props.menuItem)[0]
        const targetImageId = this.getRotatedImageIfNeeded(this.props.screenNumber, direction.imageId);
        const image = Images.filter(x => x.id === targetImageId)[0];
        if(image.image!==this.state.image) {
            this.setState({image: image.image})
        }
        const position = this.getRotatedPinPositionIfNeeded(this.props.screenNumber, { left: direction.leftPosition, top: direction.topPosition });
        if (this.state.pointerPosition.left !== position.left || this.state.pointerPosition.top !== position.top) {
            this.setState({pointerPosition: position})
        }
    }

    checkImageChange=()=>{
        if(this.props.currentResident){
            const resident = this.props.currentResident
            const targetImageId = this.getRotatedImageIfNeeded(this.props.screenNumber, resident.imageId);
            const image = Images.filter(x => x.id === targetImageId)[0]
            this.setState({ image: image.image })
            const position = this.getRotatedPinPositionIfNeeded(this.props.screenNumber, { left: resident.leftPosition, top: resident.topPosition });
            this.setState({ pointerPosition: position });
        }
        if(this.props.currentActivity){
            const activity = this.props.currentActivity
            const targetImageId = this.getRotatedImageIfNeeded(this.props.screenNumber, activity.imageId);
            const image = Images.filter(x => x.id ===targetImageId)[0]
            this.setState({ image: image.image })
            const position = this.getRotatedPinPositionIfNeeded(this.props.screenNumber, { left: activity.leftPosition, top: activity.topPosition });
            this.setState({ pointerPosition: position });
        }
    }

    chooseDefaultImage=()=>{
        const location = this.props.screenNumber

        let defaultImage = DefaultImages.filter(x=>x.screenNumber===1)[0];
        let defaultPosition = DefaultPositions.filter(x=>x.screenNumber===1)[0];

        if(location===2){
            defaultImage = DefaultImages.filter(x=>x.screenNumber===2)[0]
            defaultPosition = DefaultPositions.filter(x=>x.screenNumber===2)[0]
        }
        else if(location===3){
            defaultImage = DefaultImages.filter(x=>x.screenNumber===3)[0]
            defaultPosition = DefaultPositions.filter(x=>x.screenNumber===3)[0]
        }
        else if(location===4){
            defaultImage = DefaultImages.filter(x=>x.screenNumber===4)[0]
            defaultPosition = DefaultPositions.filter(x=>x.screenNumber===4)[0]
        }
        else if(location===5){
            defaultImage = DefaultImages.filter(x=>x.screenNumber===5)[0]
            defaultPosition = DefaultPositions.filter(x=>x.screenNumber===5)[0]
        }
        else if(location===6){
            defaultImage = DefaultImages.filter(x=>x.screenNumber===5)[0]
            defaultPosition = DefaultPositions.filter(x=>x.screenNumber===6)[0]
        }

        this.setState({image:defaultImage.url})
        this.setState({screenPosition:{left: defaultPosition.left,top: defaultPosition.top}})
    }

    showScreenPosition=()=>{ // Only show "You Are Here" when we're on the same floor as the current display
        const location = this.props.screenNumber

        if ((location === 1 && this.state.image === Sal2rot270)
            || (location === 2 && this.state.image === Sal2rot180)
            || (location === 3 && this.state.image === Sal1rot270)
            || (location === 4 && this.state.image === Sal1rot180)) {

            return true;
        }
        if((location===5 || location===6) && this.state.image===Stuen){
            return true;
        }

        return false;
    }

    setHeader=()=>{
        let header = "";
        const image = this.state.image
        if(image===Sal1 || image===Sal1rot270 || image===Sal1rot180) {header = "1. sal"}
        if(image===Sal2 || image===Sal2rot270 || image===Sal2rot180) {header = "2. sal"}
        if(image===Stuen || image===Stuenrot270 || image===Stuenrot180) {header = "Stuen"}
        if (image === Part1 || image === Part1rot270 || image === Part1rot180
            || image === Part2 || image === Part2rot270 || image === Part2rot180
            || image === Part3 || image === Part3rot270 || image === Part3rot180
            || image === Sal3 || image === Sal3rot270 || image === Sal3rot180
            ) {
            if(this.props.currentResident){
                const resident = this.props.currentResident
                header = resident.floor.toString() + ". Sal " /*+ "- gruppe " + resident.group.toString()*/
            } else {
                header = "3. sal"
            }
        }

        if(this.state.header!==header){
            this.setState({header:header})
        }
    }

    render(){

        const { currentResident, menuItem, screenNumber, currentActivity, showArrow } = this.props

        const showPointer = (currentResident ||
                            currentActivity ||
                            DirectionsForLocationsWithoutSublocations.map(x=>x.menuId).includes(menuItem))
                            && this.state.pointerPosition.top!==0

        const showScreenPosition = this.showScreenPosition()

        const destination = currentActivity ? currentActivity.location : 
            currentResident ? currentResident.place : 
                (DirectionsForLocationsWithoutSublocations.map(x=>x.menuId).includes(this.props.menuItem) && !currentActivity && !currentResident) ? 
                    menuItem.toString() : '';

        //console.log("destination dump " + destination);
        //console.log("currentActivity? " + currentActivity);
        //console.log("currentActivity location: " + (currentActivity ? currentActivity?.location : "no activity"));
        //console.log("currentResident?" + currentResident);
        //console.log("currentResident place: " + (currentResident ? currentResident.place : "no resident"));
        //console.log("(DirectionsForLocationsWithoutSublocations.map(x=>x.menuId).includes(this.props.menuItem): " + (DirectionsForLocationsWithoutSublocations.map(x => x.menuId).includes(this.props.menuItem)));
        //console.log("menuItem.toString(): " + menuItem.toString());

        const floor = currentActivity ? currentActivity.floor :
            currentResident ? currentResident.floor :
                (DirectionsForLocationsWithoutSublocations.map(x => x.menuId).includes(this.props.menuItem) && !currentActivity && !currentResident) ?
                    DirectionsForLocationsWithoutSublocations.filter(x => x.menuId === this.props.menuItem)[0].floor : 0; //Aske: Not sure what to return in the case that the floor is not set correctly

        return (
            <div className={currentResident !== null && screenNumber === 6 && (menuItem !== 6 && menuItem !== 7 && menuItem !== 8) ? "Map-Bigger" : "Map"}>
                {showPointer?(<i className="Pointer"
                                 style={{ left:`${this.state.pointerPosition.left}px`,
                                     top:`${this.state.pointerPosition.top}px`}}
                />):''}
                <div className="Map-header" >
                    {this.state.header}
                </div>
                <div className="Picture"
                     style={{backgroundImage: `url(${this.state.image})`,
                     width: currentResident !== null && screenNumber === 6 && (menuItem !== 6 && menuItem !== 7 && menuItem !== 8) ? 1550 : `${this.state.imageSize.width}px`,
                     height:`${this.state.imageSize.height}px`}
                     }>
                    {showScreenPosition?<i className="Screen-position"
                       style={{ left:`${this.state.screenPosition.left}px`,
                       top:`${this.state.screenPosition.top}px`}}
                    />:''}
                    
                    <Modal isOpen={showArrow} style={modalStyles}>
                        <h1 className="Message" >Gå til elevatoren</h1>
                        <i className="Arrow" />
                    </Modal>
                    
                    {showPointer && 
                        <div className="Route">
                            <Route 
                                screenNumber={screenNumber}
                                destination={destination}
                                floor={floor}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Map;