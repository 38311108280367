import React from 'react';
import './ListPart.css';
import 'react-simple-keyboard/build/css/index.css';
import {IResidentDTO} from '../../Domains/IResidentDTO'
import {IActivityDTO} from "../../Domains/IActivityDTO";


interface ListProps{
    menuItem: number;
    input:string;
    handleShowKeyboard: (show: boolean)=>void;
    header: string;
    handleResidentChange: (resident: IResidentDTO)=>void;
    handleActivityChange: (activity: IActivityDTO)=>void;
    residents: IResidentDTO[];
    activities: any;
    showAdditionalScreens: boolean;
    screenNumber: number;
}

interface ListState{
    layoutName: string,
    input: string,
    header: string
}

const Directions = [
    {id: 1, name: "Læger"},
    {id: 2, name: "Behandlere"},
    {id: 3, name: "Sygeplejeklinik"},
    {id: 4, name: "Ledere"},
    {id: 5, name: "Administration"},
    {id: 6, name: "Psykolog"},
    {id: 7, name: "None"}
]

class ListPart extends React.PureComponent<ListProps, ListState>{

    state:ListState = {
        layoutName: "default",
        input: this.props.input,
        header: ""
    };

    componentDidUpdate(prevProps: Readonly<ListProps>, prevState: Readonly<ListState>, snapshot?: any) {
        this.setState({input:this.props.input})
        this.setState({header:this.props.header})
    }

    renderPersonLocationList = (items:any) => {

        const handleResidentChange = this.props.handleResidentChange

        return(
            <div className="List-part">
                <div className="Header">
                    {this.state.header}
                </div>
                <div className="Person-location-head">
                    <div className="Column-element head-element">Person</div>
                    <div className="Column-element head-element">Placering</div>
                    <div className="Column-element head-element">Etage</div>
                </div>
                {items.map(function (value:IResidentDTO, index:number){
                    return (
                        <div className="Person-location"
                             style={index%2===0?{backgroundColor:'rgba(0,0,0,.05)'}:{backgroundColor:'transparent'}}
                             onClick={()=>handleResidentChange(value)}
                        >
                            <div className="Column-element head-element">{value.name}</div>
                            <div className="Column-element">{value.place}</div>
                            <div className="Column-element">{value.floor}</div>
                            <div className="Column-element">
                                <div className="List-circle">
                                </div>
                            </div>
                        </div>)
                })}
            </div>
        )
    }

    searchList = (items: any, background: number) =>{

        const handleResidentChange = this.props.handleResidentChange

        return(
            items.map(function (value:IResidentDTO, index:number){
            return (
                <div className=" Person-location Residents"
                     style={index%2===background?{backgroundColor:'rgba(0,0,0,.05)'}:{backgroundColor:'transparent'}}
                     onClick={()=>handleResidentChange(value)}
                >
                    <div className="Column-element">{value.name}</div>
                    <div className="Column-element">{value.place}</div>
                    <div className="Column-element">
                        <div className="List-circle">
                        </div>
                    </div>
                </div>)
            })
        )
    }

    renderSearchList = (items:any) => {

        const filteredItems = this.state.input.length>0?items.filter((x:IResidentDTO)=>x.name.toLowerCase().includes(this.state.input.toLowerCase())):[]

        return(
            <div className="List-part">
                <div className="Scroll-additional-list">
                <input className={"Input"}
                    value={this.state.input}
                    placeholder={"Søg beboer"}
                    onFocus={()=>this.props.handleShowKeyboard(true)}
                    onChange={()=>{}}
                />
                {/*<div className="Search-resident-head">
                    <div className="Search-resident-element">Navn</div>
                    <div className="Search-resident-element">Etage</div>
                </div>*/}
                <div>
                    {filteredItems.length>0 ?
                        this.searchList(filteredItems,0) :
                        this.state.input.length>0 ?
                            (<div className="No-result">
                            ingen resultat fundet for {this.state.input}
                            </div>):
                            (<div style={{height:'25px'}}></div>)
                    }
                </div>
                <div className="All-resident">
                    <div className="All-resident-text">
                        Alle beboer
                    </div>
                </div>
                <div>
                    {this.searchList(items,1)}
                </div>
                </div>
            </div>
        )
    }

    renderActivityList =(items: any)=>{

        const handleActivityChange = this.props.handleActivityChange;

        return(
            <div className="List-part">
                <div className="Header">
                    Mødeoversigt
                </div>
                <div className="Scroll">
                <div className="Activity-head">
                    <div className="Column-element head-element"></div>
                    <div className="Column-element head-element">Tid</div>
                    <div className="Column-element head-element">Aktivitet</div>
                    <div className="Column-element head-element">Lokale</div>
                    <div className="Column-element head-element">Etage</div>
                    <div className="Column-element head-element"></div>
                </div>
                {items.map(function (value:IActivityDTO, index:number){
                    return (
                        <div className="Activity"
                             style={index%2===0?{backgroundColor:'rgba(0,0,0,.05)'}:{backgroundColor:'transparent'}}
                             onClick={()=>handleActivityChange(value)}
                        >
                            <div className="Column-element"></div>
                            <div className="Column-element head-element">{value.time}</div>
                            <div className="Column-element">{value.name}</div>
                            <div className="Column-element">{value.location}</div>
                            <div className="Column-element">{value.floor}</div>
                            <div className="Column-element vertical-align">
                                <div className="List-circle">
                                </div>
                            </div>
                        </div>)
                })}
                </div>
            </div>
        )
    }

    residentFilter = (residents: IResidentDTO[])=>{
        const menuItem = this.props.menuItem
        const location = this.props.screenNumber

        if(menuItem===0 && location===6){
            return residents.filter(x=>x.direction===7);
        }

        if(menuItem===1){
            return residents.filter(x=>x.direction===1);
        }
        if(menuItem===2){
            return residents.filter(x=>x.direction===2);
        }
        if(menuItem===3){
            return residents.filter(x=>x.direction===3);
        }
        if(menuItem===4){
            return residents.filter(x=>x.direction===8);
        }
        if(menuItem===5){
            return residents.filter(x=>x.direction===9);
        }
        if(menuItem===6){
            return residents.filter(x=>x.direction===10);
        }
        if(menuItem===7){
            return residents.filter(x=>x.direction===4);
        }
        if(menuItem===8){
            return residents.filter(x=>x.direction===5);
        }
        if(menuItem===9){
            return residents.filter(x=>x.direction===11);
        }
        if(menuItem===10){
            return residents.filter(x=>x.direction===6);
        }

        if(menuItem===11){
            return residents.filter(x=>x.direction===7 && x.floor===3);
        }
        if(menuItem===12){
            return residents.filter(x=>x.direction===7 && x.floor===4);
        }
        if(menuItem===13){
            return residents.filter(x=>x.direction===7 && x.floor===5);
        }

        return [];
    }

    renderResidentsByFloor = (residents: IResidentDTO[])=>{
        return (
            <div className="Scroll-additional-list">
                <div className="Header">
                    {this.state.header}
                </div>
                <div className="Search-resident-head">
                    <div className="Search-resident-element">Navn</div>
                    <div className="Search-resident-element">Etage</div>
                </div>
                <div>
                    {this.searchList(residents,0)}
                </div>
            </div>
        )
    }

    render(){
        //Warning: Each child in a list should have a unique "key" prop.
        //https://react.dev/learn/rendering-lists#keeping-list-items-in-order-with-key
        const location = this.props.screenNumber

        const showDefaultAdditionalList = location===6 && this.props.menuItem===0
        const showActivityList = (this.props.menuItem===0 && location!==6 && !this.props.showAdditionalScreens)

        const showPersonLocation = (this.props.menuItem!==0 || (this.props.showAdditionalScreens && location!==8))
                                    && this.props.menuItem!==11
                                    && this.props.menuItem!==12
                                    && this.props.menuItem!==13
                                    && location!==9
                                    && location!==10
                                    && location!==11

        const showResidentsByFloor = location === 6 &&
                                    (this.props.menuItem === 11
                                    || this.props.menuItem === 12
                                    || this.props.menuItem === 13)


        const filteredResidents = this.residentFilter(this.props.residents)

        return (
            <div className="List-part">
                {showPersonLocation?this.renderPersonLocationList(filteredResidents):''}
                {showDefaultAdditionalList ? this.renderSearchList(filteredResidents):''}
                {showActivityList ? this.renderActivityList(this.props.activities): ''}
                {showResidentsByFloor ? this.renderResidentsByFloor(filteredResidents):''}
            </div>
        )
    }
}

export default ListPart;