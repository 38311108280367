import React, { Component } from "react";
import Keyboard from "react-simple-keyboard";
import './Keyboard.css';

interface KeyboardProps{
    handleInputChanges: (input: string)=>void;
    handleShowKeyboard: (show: boolean)=>void;
    input:string;
}

interface KeyboardState{
    layoutName:string;
    input:string;
}

class MyKeyboard extends Component<KeyboardProps, KeyboardState>{

    constructor(props: KeyboardProps) {
        super(props);
        this.state = {
            layoutName: "default",
            input: this.props.input
        };
    };

    onChange = (input:any) => {
        this.setState({ input });
        this.props.handleInputChanges(input);
    };

    onKeyPress = (button:any) => {
        if (button === "{caps}") this.handleShift();
        if (button==="{enter}") this.props.handleShowKeyboard(false);
    };

    handleShift = () => {
        const layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default"
        });
    };

    render() {
        return (
            <div>
                <Keyboard
                    layoutName={this.state.layoutName}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    layout={{
                        'default': [
                            '1 2 3 4 5 6 7 8 9 0 {backspace}',
                            'q w e r t y u i o p å _',
                            '{caps} a s d f g h j k l æ ø',
                            '{enter} z x c v b n m @ . -',
                            '{space}'
                        ],
                        'shift': [
                            '1 2 3 4 5 6 7 8 9 0 {backspace}',
                            'Q W E R T Y U I O P Å _',
                            '{caps} A S D F G H J K L Æ Ø',
                            '{enter} Z X C V B N M @ . -',
                            '{space}'
                        ]


                    }}

                    display={{
                        '{backspace}': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 12.59L17.59 17 14 13.41 10.41 17 9 15.59 12.59 12 9 8.41 10.41 7 14 10.59 17.59 7 19 8.41 15.41 12 19 15.59z"/></svg>',
                        '{enter}': ' ',
                        '{caps}': ' <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 8.41L16.59 13 18 11.59l-6-6-6 6L7.41 13 12 8.41zM6 18h12v-2H6v2z"/></svg> ',
                        '{space}': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 9v4H6V9H4v6h16V9z"/></svg>'								
                    }}

                    theme={"hg-theme-default Keyboard-style"}
                />
            </div>
        );
    }
}

export default MyKeyboard;
