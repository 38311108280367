const secondFloorAnchors = [
  { locationX: 168, locationY: 574 }, // start position bottom left
  { locationX: 646, locationY: 246 }, // start position top right
  { locationX: 200, locationY: 340 }, // left middle
  { locationX: 600, locationY: 345 }, // right middle
  { locationX: 600, locationY: 245 }, // right top
  { locationX: 210, locationY: 240 }, // left top
  { locationX: 183, locationY: 523 }, // left bottom
  { locationX: 330, locationY: 527 }, // right bottom
]

const firstFloorAnchors = [
  { locationX: 160, locationY: 534 }, // start position bottom left
  { locationX: 696, locationY: 246 }, // start position top right
  { locationX: 190, locationY: 405 }, // left middle
  { locationX: 620, locationY: 410 }, // right middle
  { locationX: 610, locationY: 246 }, // right top
  { locationX: 305, locationY: 240 }, // center top
  { locationX: 180, locationY: 517 }, // left bottom
  { locationX: 295, locationY: 405 }, // center
]

const FifthURLRoutes = {
  "4": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 590, locationY: 245 }
  ],
  "5": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 610, locationY: 246 }, 
    { locationX: 610, locationY: 250 }, 
    { locationX: 620, locationY: 400 }, 
    { locationX: 628, locationY: 400 }, 
    { locationX: 655, locationY: 400 }, 
  ],
  "9": [
    { locationX: 460, locationY: 475 }, 
    { locationX: 530, locationY: 475 }, 
    { locationX: 531, locationY: 475 }, 
    { locationX: 522, locationY: 375 },
    { locationX: 530, locationY: 375 },
    { locationX: 540, locationY: 375 }
  ],
  "2.021": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 200, locationY: 240 }
  ],
  "2.025": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 290, locationY: 240 }
  ],
  "2.037": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 565, locationY: 245 }
  ],
  "2.052": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 349 }
  ],
  "2.054": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 340 }, 
    { locationX: 603, locationY: 345 }, 
    { locationX: 660, locationY: 345 }
  ],
  "2.040": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 340 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 420, locationY: 343 }
  ],
  "2.048": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 545, locationY: 345 }
  ],
  "2.044": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 480, locationY: 343 }
  ],
  "2.056": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 720, locationY: 347 }
  ],
  "2.033": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 510, locationY: 245 }
  ],
  "Køkkenalrummet": [    
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 200, locationY: 340 },
    { locationX: 200, locationY: 345 },
    { locationX: 183, locationY: 523 }, 
    { locationX: 190, locationY: 523 }, 
    { locationX: 330, locationY: 527 }
  ],
  "Udsigten": [    
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 200, locationY: 340 },
    { locationX: 200, locationY: 345 },
    { locationX: 175, locationY: 590 }, 
    { locationX: 167, locationY: 590 }, 
    { locationX: 155, locationY: 590 }
  ],
  "Loungen": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 200, locationY: 340 },
    { locationX: 200, locationY: 345 },
    { locationX: 183, locationY: 523 }, 
    { locationX: 190, locationY: 523 }, 
    { locationX: 330, locationY: 527 },
    { locationX: 330, locationY: 535 }, 
    { locationX: 330, locationY: 565 } 
  ],
  "Skoven": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 200, locationY: 340 },
    { locationX: 200, locationY: 345 },
    { locationX: 185, locationY: 495 }
  ],
  "Flexsal 2": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 610, locationY: 246 }, 
    { locationX: 610, locationY: 250 }, 
    { locationX: 620, locationY: 410 }, 
    { locationX: 615, locationY: 410 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 190, locationY: 415 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 190, locationY: 516 }, 
    { locationX: 260, locationY: 517 }
  ],  
  "Spejlsalen": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 610, locationY: 246 }, 
    { locationX: 610, locationY: 250 }, 
    { locationX: 620, locationY: 410 }, 
    { locationX: 615, locationY: 410 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 190, locationY: 415 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 190, locationY: 516 }, 
    { locationX: 260, locationY: 517 }
  ],  
  "Store træningssal": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 610, locationY: 246 }, 
    { locationX: 610, locationY: 250 }, 
    { locationX: 620, locationY: 410 }, 
    { locationX: 615, locationY: 410 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 192, locationY: 395 }, 
    { locationX: 203, locationY: 267 }
  ],
  "Konference": [
      { locationX: 656, locationY: 246 },
      { locationX: 610, locationY: 246 },
      { locationX: 605, locationY: 246 },
      { locationX: 570, locationY: 346 },
      { locationX: 570, locationY: 345 },
      { locationX: 525, locationY: 345 }
  ],
  "Lille træningssal": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 610, locationY: 246 }, 
    { locationX: 610, locationY: 250 }, 
    { locationX: 620, locationY: 410 }, 
    { locationX: 615, locationY: 410 }, 
    { locationX: 190, locationY: 403 }, 
    { locationX: 190, locationY: 395 }, 
    { locationX: 195, locationY: 350 }
  ],
  "1.023": [
    { locationX: 696, locationY: 246 },  
    { locationX: 540, locationY: 243 }
  ],
  " 0.047": [
    { locationX: 460, locationY: 475 }, 
    { locationX: 530, locationY: 475 }, 
    { locationX: 531, locationY: 475 }, 
    { locationX: 522, locationY: 375 },
    { locationX: 530, locationY: 375 },
    { locationX: 540, locationY: 375 }
  ],
  "1.017": [
    { locationX: 696, locationY: 246 },  
    { locationX: 380, locationY: 240 }
  ],
  "1.025": [
    { locationX: 696, locationY: 246 },  
    { locationX: 595, locationY: 243 }
  ],
  "1.019": [
    { locationX: 696, locationY: 246 },  
    { locationX: 455, locationY: 241 }
  ],
}

export default FifthURLRoutes