import React, {Component} from 'react';
import './Menu.css';
import Ledere from "../../Images/Ledere.svg"
import Laeger from "../../Images/Laeger.svg"
import Psykolog from "../../Images/Psykolog.svg"
import Rehabilitering from "../../Images/Rehabilitering.svg"
import Sygeplejeklinik from "../../Images/Sygeplejeklinik.svg"
import Tandlaege from "../../Images/Tandlaege.svg"
import Tekniskservice from "../../Images/Tekniskservice.svg"
import Genoptraening from "../../Images/Genoptraening.svg"
import Behandlere from "../../Images/Behandlere.svg"
import Administration from "../../Images/Administration.svg"

import Sal3 from "../../Images/3sal.svg"
import Sal4 from "../../Images/4sal.svg"
import Sal5 from "../../Images/5sal.svg"
import {IActivityDTO} from "../../Domains/IActivityDTO";

const realMenuItems = [
    {id:1,name:"Læger",image:Laeger, title:"Læger"},
    {id:2,name:"Behandlere",image:Behandlere,title:"Behandlere"},
    {id:3,name:"Sygeplejeklinik",image:Sygeplejeklinik,title:"Sygeplejeklinik"},
    {id:4,name:"Omsorgstandplejen",image:Tandlaege,title:"Omsorgstandplejen"},
    {id:5,name:"Genoptræning",image:Genoptraening,title:"Genoptræning"},
    {id:6,name:"Rehabilitering",image:Rehabilitering,title:"Rehabilitering"},
    {id:7,name:"Ledere",image:Ledere,title:"Ledere"},
    {id:8,name:"Administration",image:Administration,title:"Administration"},
    {id:9,name:"Teknisk Service",image:Tekniskservice,title:"Teknisk Service"},
    {id:10,name:"Psykolog",image:Psykolog,title:"Psykolog"}
]

const additionalMenuItems = [
    {id:6,name:"Rehabilitering",image:Rehabilitering, title:"Rehabilitering"},
    {id:8,name:"Administration",image:Administration, title:"Administration"},
    {id:7,name:"Ledere",image:Ledere, title:"Ledere"},
    {id:11,name:"3. Sal",image:Sal3, title:"Beboer 3. Sal"},
    {id:12,name:"4. Sal",image:Sal4, title:"Beboer 4. Sal"},
    {id:13,name:"5. Sal",image:Sal5, title:"Beboer 5. Sal"},
]

interface MenuProps{
    screenNumber: number;
    handleChangeOfMenuItem: (id:number)=>void,
    handleChangeHeader: (header: string) =>void,
    handleActivityChange: (activity: IActivityDTO | null)=>void;
    menuItem: number;
}

interface MenuState{
    isChecked:number;
}



class Menu extends React.PureComponent<MenuProps, MenuState>{

    state:MenuState = {
        isChecked:0
    };



    componentDidUpdate(prevProps: Readonly<MenuProps>, prevState: Readonly<MenuState>, snapshot?: any) {
        if(this.props.menuItem===0){
            this.setState({isChecked:0})
        }
    }

    choose = (id:number) =>{
        if(this.state.isChecked===id){
            this.setState({isChecked:0});
        }
        else this.setState({isChecked:id});
        this.props.handleActivityChange(null);
    }

    render() {

        const location = this.props.screenNumber !==6

        const menu = this.props.screenNumber!==6 ? realMenuItems:additionalMenuItems

        //Warning: Each child in a list should have a unique "key" prop.
        //https://react.dev/learn/rendering-lists#keeping-list-items-in-order-with-key
        return (
            <div className="MenuContainer">
            <div className="Menu" style={location?{gridTemplateColumns:"50% 50%"}:{gridTemplateColumns:"100%", maxHeight:"885px",
                                                                                    overflow:"scroll",overflowX:"hidden"}}>
                {menu.map((value, index, array) => {
                    return (<div  className="Height">
                            <div className="Card-container" >
                                <div className={this.state.isChecked===value.id?"Circle-clicked":"Circle"}>
                                    <img  src={value.image} alt={"logo"} className={"Image-style"}
                                          onClick={()=>{this.choose(value.id)
                                            this.props.handleChangeOfMenuItem(value.id);
                                            this.props.handleChangeHeader(value.title)
                                          }}
                                    />
                                </div>
                                <div className="Card-title">
                                    {value.name}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            </div>
        );
    }


}

export default Menu;
