const secondFloorAnchors = [
  { locationX: 173, locationY: 574 }, // start position bottom left
  { locationX: 646, locationY: 246 }, // start position top right
  { locationX: 200, locationY: 340 }, // left middle
  { locationX: 600, locationY: 345 }, // right middle
  { locationX: 600, locationY: 245 }, // right top
  { locationX: 210, locationY: 240 }, // left top
  { locationX: 183, locationY: 523 }, // left bottom
  { locationX: 330, locationY: 527 }, // right bottom
]

const firstFloorAnchors = [
  { locationX: 160, locationY: 534 }, // start position bottom left
  { locationX: 696, locationY: 246 }, // start position top right
  { locationX: 190, locationY: 405 }, // left middle
  { locationX: 620, locationY: 410 }, // right middle
  { locationX: 610, locationY: 246 }, // right top
  { locationX: 305, locationY: 240 }, // center top
  { locationX: 180, locationY: 517 }, // left bottom
  { locationX: 295, locationY: 405 }, // center
]

const FirstURLRoutes = {
  "4": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 }, 
    { locationX: 210, locationY: 245 }, 
    { locationX: 210, locationY: 242 }, 
    { locationX: 600, locationY: 245 }
  ],
  "5": [
    { locationX: 173, locationY: 560 },  
    { locationX: 190, locationY: 405 }, 
    { locationX: 197, locationY: 405 }, 
    { locationX: 655, locationY: 410 }
  ],
  "9": [
    { locationX: 550, locationY: 555 }, 
    { locationX: 535, locationY: 555 }, 
    { locationX: 535, locationY: 550 }, 
    { locationX: 519, locationY: 375 },
    { locationX: 525, locationY: 375 },
    { locationX: 535, locationY: 375 }
  ],
  "2.021": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 210, locationY: 245 },
    { locationX: 205, locationY: 240 },
    { locationX: 197, locationY: 240 }
  ],
  "2.025": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 210, locationY: 245 }, 
    { locationX: 212, locationY: 240 }, 
    { locationX: 300, locationY: 240 }
  ],
  "2.037": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 210, locationY: 245 }, 
    { locationX: 212, locationY: 240 }, 
    { locationX: 570, locationY: 245 }
  ],
  "2.052": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 }, 
    { locationX: 600, locationY: 345 }
  ],
  "2.054": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 }, 
    { locationX: 660, locationY: 345 }
  ],
  "2.040": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 },
    { locationX: 430, locationY: 343 }
  ],
  "2.048": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 },
    { locationX: 540, locationY: 345 }
  ],
  "2.044": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 }, 
    { locationX: 485, locationY: 343 }
  ],
  "2.056": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 200, locationY: 335 }, 
    { locationX: 207, locationY: 340 }, 
    { locationX: 720, locationY: 347 }
  ],
  "2.033": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 180, locationY: 510 },
    { locationX: 210, locationY: 245 },
    { locationX: 210, locationY: 240 }, 
    { locationX: 520, locationY: 245 }
  ],
  "Køkkenalrummet": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 185, locationY: 525 },  
    { locationX: 193, locationY: 525 }, 
    { locationX: 330, locationY: 527 }
  ],
  "Loungen": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 185, locationY: 525 },  
    { locationX: 193, locationY: 525 },  
    { locationX: 330, locationY: 527 },
    { locationX: 328, locationY: 537 }, 
    { locationX: 328, locationY: 565 } 
  ],
  "Skoven": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 185, locationY: 525 },  
    { locationX: 185, locationY: 515 }, 
    { locationX: 185, locationY: 490 }
  ],
  "Flexsal 2": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 190, locationY: 517 }, 
    { locationX: 260, locationY: 517 }
  ],  
  "Spejlsalen": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 180, locationY: 517 }, 
    { locationX: 190, locationY: 517 }, 
    { locationX: 260, locationY: 517 }
  ],  
  "Store træningssal": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 204, locationY: 267 }
  ],
  "Lille træningssal": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 190, locationY: 350 }
  ],
  "Konference": [
      { locationX: 162, locationY: 538 },
      { locationX: 185, locationY: 525 },
      { locationX: 185, locationY: 525 },
      { locationX: 200, locationY: 340 },
      { locationX: 210, locationY: 340 },
      { locationX: 530, locationY: 345 }
  ],  
  "Udsigten": [
    { locationX: 162, locationY: 538 }, 
    { locationX: 175, locationY: 550 }, 
    { locationX: 170, locationY: 552 },
    { locationX: 167, locationY: 580 },
    { locationX: 163, locationY: 585 },
    { locationX: 150, locationY: 593 }
  ],
  "1.023": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 195, locationY: 405 }, 
    { locationX: 295, locationY: 405 },
    { locationX: 295, locationY: 397 }, 
    { locationX: 305, locationY: 240 },  
    { locationX: 310, locationY: 240 }, 
    { locationX: 550, locationY: 243 },
  ],
  " 0.047": [
    { locationX: 550, locationY: 555 }, 
    { locationX: 535, locationY: 555 }, 
    { locationX: 535, locationY: 550 }, 
    { locationX: 519, locationY: 375 },
    { locationX: 525, locationY: 375 },
    { locationX: 535, locationY: 375 }
  ],
  
  "1.017": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 195, locationY: 405 }, 
    { locationX: 295, locationY: 405 },
    { locationX: 295, locationY: 397 }, 
    { locationX: 305, locationY: 240 },   
    { locationX: 310, locationY: 240 }, 
    { locationX: 390, locationY: 240 },
  ],
  "1.025": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 195, locationY: 405 }, 
    { locationX: 295, locationY: 405 },
    { locationX: 295, locationY: 397 }, 
    { locationX: 305, locationY: 240 },  
    { locationX: 310, locationY: 240 }, 
    { locationX: 605, locationY: 243 },
  ],
  "1.019": [
    { locationX: 173, locationY: 560 }, 
    { locationX: 190, locationY: 405 }, 
    { locationX: 195, locationY: 405 }, 
    { locationX: 295, locationY: 405 },
    { locationX: 295, locationY: 397 }, 
    { locationX: 305, locationY: 240 },  
    { locationX: 310, locationY: 240 }, 
    { locationX: 450, locationY: 241 },
  ],
}

export default FirstURLRoutes