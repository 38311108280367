import {IPersonDTO} from "../Domains/IPersonDTO";
import axios from "axios";
import {IMapDTO} from "../Domains/MapDTO";
import { IBookingDTO } from "../Domains/IBookingDTO";

const apiUrl = 'https://bkwebapi.app-infotv.net';

//const apiUrl = 'https://localhost:44308'

export const getAllResidents = async (): Promise<IPersonDTO[]> =>
    (await axios.get(`${apiUrl}/api/locationMap/peopleWithPlaces`)).data as IPersonDTO[];

export const getMaps = async (): Promise<IMapDTO[]> =>
    (await axios.get(`${apiUrl}/api/locationMap/maps`)).data as IMapDTO[];

export const getActivities = async (): Promise<IBookingDTO[]> =>
    (await axios.get(`${apiUrl}/api/locationMap/activities`)).data as IBookingDTO[];