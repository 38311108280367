import React from 'react';
import './ResidentsFloor.css';
import {IShortResidentDTO} from "../../Domains/IShortResidentDTO";
import {IResidentDTO} from "../../Domains/IResidentDTO";

interface Props{
    residents: IShortResidentDTO[];
}

interface State{
}

class ResidentsFloor3 extends React.PureComponent<Props, State>{

    renderList = (items: any) =>{

        return(
        items.map(function (value:IResidentDTO, index:number){
            return (
                <div style={index%2===1?{backgroundColor:'white'}:{backgroundColor:'#efefef'}}
                     className="additional-list-element"
                >
                    <div></div>
                    <div>{value.name}</div>
                    <div>{value.place}</div>
                </div>)
        }))
    }


    render(){

        let residents = this.props.residents

        residents = residents.sort((a,b)=>parseInt(a.place.substr(5,2))-parseInt(b.place.substr(5,2)))

        const firstColumn = residents.slice(0,9)
        const secondColumn = residents.slice(9,residents.length)

        return (
            <div>
                <div className="list-3">
                    <div className="header">
                        Stationsporten 10
                    </div>
                </div>
                <div className="list-3">
                    <div>
                        {this.renderList(firstColumn)}
                        <div style={{backgroundColor:'white', height:"66px"}} >
                        </div>
                        <div style={{backgroundColor:'#efefef', height:"66px"}}>
                        </div>
                    </div>
                    <div>{this.renderList(secondColumn)}</div>
                </div>
            </div>
        )
    }
}

export default ResidentsFloor3;