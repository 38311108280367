import React from "react";
import './DigitalClock.css';
import moment from 'moment'
import 'moment/locale/da'

interface IState {
    time: string;
    date: string;
}

export class DigitalClock extends React.Component<{}, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            time: '',
            date: ''
        };

        this.timeout = setTimeout(this.step, this.interval);
    } 

    private interval = 1000;
    private expected = Date.now() + this.interval;

    private updateDateTime = () => {
        const dateTime = new Date();
        this.setState({
            date: dateTime.toDateString(),
            time: dateTime.toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'})
        });
    }

    private step = () => {
        var dt = Date.now() - this.expected; // the drift (positive for overshooting)
        if (dt > this.interval) {
        }

        this.updateDateTime();

        this.expected += this.interval;
        setTimeout(this.step, Math.max(0, this.interval - dt)); // take into account drift
    }

    private timeout: ReturnType<typeof setTimeout>;

    public componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    componentDidMount = () => {
        moment.locale('da')
    }

    render() {
        const { date, time } = this.state;

        return (
            <div className="clock-container" style={{ background: "#324c6b" }}>
                <div className="clock-date">
                    { date ? `${moment(date).format('dddd')}, ${moment(date).format('DD. MMMM YYYY')}` : `${moment().format('dddd')}, ${moment(new Date()).format('DD. MMMM YYYY')}` }
                </div>
                <div className="clock-time">
                    <p>{ time ? time : `${new Date().toLocaleTimeString('de-DE', {hour: '2-digit', minute:'2-digit'})}` }</p>
                </div>
            </div>
        );
    }
}