import React from 'react';
import './ResidentsFloor.css';
import {IShortResidentDTO} from "../../Domains/IShortResidentDTO";
import {IResidentDTO} from "../../Domains/IResidentDTO";

interface Props{
    residents: IShortResidentDTO[];
}

interface State{
}

class ResidentsFloor4 extends React.PureComponent<Props, State>{

    renderList = (items: any, background: number) =>{

        return(
            items.map(function (value:IResidentDTO, index:number){
                return (
                    <div style={index%2===1?{backgroundColor:'white'}:{backgroundColor:'#efefef'}}
                         className="additional-list-element"
                    >
                        <div></div>
                        <div>{value.name}</div>
                        <div>{value.place}</div>
                    </div>)
            }))
    }

    render(){

        const residents = this.props.residents

        const residentsStationsporten4 = residents.filter(x=>x.place.startsWith('4')).sort((a,b)=>parseInt(a.place.substr(4,2))-parseInt(b.place.substr(4,2)))
        const residentsStationsporten10 = residents.filter(x=>x.place.startsWith('10')).sort((a,b)=>parseInt(a.place.substr(5,2))-parseInt(b.place.substr(5,2)))
        const firstPartResidents10 = residentsStationsporten10.slice(0,9)
        const secondPartResidents10 = residentsStationsporten10.slice(9,residentsStationsporten10.length)

        return (
            <div>
                <div className="header-4">
                    <div className="header">
                        Stationsporten 4
                    </div>
                    <div className="header">
                        Stationsporten 10
                    </div>
                    <div className="header">
                        Stationsporten 10
                    </div>
                </div>
                <div className="list">
                    <div>
                        {this.renderList(residentsStationsporten4,0)}
                        <div style={{backgroundColor:'#efefef', height:"66px"}}>
                        </div>
                        <div style={{backgroundColor:'white', height:"66px"}}>
                        </div>
                    </div>
                    <div>
                        {this.renderList(firstPartResidents10,0)}
                        <div style={{backgroundColor:'white', height:"66px"}} >
                        </div>
                        <div style={{backgroundColor:'#efefef', height:"66px"}}>
                        </div>
                    </div>
                    <div>
                        {this.renderList(secondPartResidents10,0)}
                    </div>
                </div>
            </div>
        )
    }
}

export default ResidentsFloor4;