const residentFloorAnchors = [
  { locationX: 1496, locationY: 500 }, // start position
  { locationX: 1015, locationY: 490 }, // \
  { locationX: 1015, locationY: 520 }, //  \
  { locationX: 1007, locationY: 525 }, //   \ detour part
  { locationX: 885, locationY: 525 },  //   /
  { locationX: 880, locationY: 520 },  //  /
  { locationX: 880, locationY: 470 },  // /
  { locationX: 657, locationY: 467 },  // center bottom
  { locationX: 190, locationY: 300 }, // left top
  { locationX: 665, locationY: 305 }, // center top
]

const SixthURLRoutes = {
  "10.3.1": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 657, locationY: 475 },
    { locationX: 807, locationY: 477 },
    { locationX: 805, locationY: 485 },
    { locationX: 805, locationY: 489 },
  ],
  "10.3.3": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 487 },
  ],
  "10.3.4": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 450 },
    { locationX: 650, locationY: 450 },
    { locationX: 630, locationY: 450 },
  ],
  "10.3.10": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1065, locationY: 480 }, 
    { locationX: 1065, locationY: 475 },
    { locationX: 1065, locationY: 468 },
  ],
  "10.3.17": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1400, locationY: 485 }, 
    { locationX: 1010, locationY: 480 },
    { locationX: 1405, locationY: 493 },
    { locationX: 1405, locationY: 498 },
  ],
  "10.3.20": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1220, locationY: 483 }, 
    { locationX: 1225, locationY: 490 },
    { locationX: 1225, locationY: 496 },
  ],
  "10.3.18": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1350, locationY: 485 }, 
    { locationX: 1355, locationY: 493 },
    { locationX: 1355, locationY: 498 },
  ],
  "10.3.5": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 663, locationY: 377 },
    { locationX: 657, locationY: 380 },
    { locationX: 637, locationY: 379 },
  ],
  "10.3.15": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1422, locationY: 485 }, 
    { locationX: 1425, locationY: 477 },
    { locationX: 1425, locationY: 473 },
  ],
  "10.3.16": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1458, locationY: 485 }, 
    { locationX: 1455, locationY: 477 },
    { locationX: 1455, locationY: 473 },
  ],
  "10.3.13": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1295, locationY: 483 }, 
    { locationX: 1295, locationY: 475 },
    { locationX: 1295, locationY: 470 },
  ],
  "10.3.6": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 665, locationY: 357 },
    { locationX: 653, locationY: 356 },
    { locationX: 639, locationY: 356 },
  ],
  "10.3.19": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1278, locationY: 484 }, 
    { locationX: 1277, locationY: 492 },
    { locationX: 1277, locationY: 496 },
  ],
  "10.3.14": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1320, locationY: 483 }, 
    { locationX: 1323, locationY: 481 },
    { locationX: 1323, locationY: 471 },
  ],
  "10.3.11": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1160, locationY: 482 }, 
    { locationX: 1165, locationY: 478 },
    { locationX: 1165, locationY: 470 },
  ],
  "10.3.8": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 655, locationY: 475 },
    { locationX: 772, locationY: 477 },
    { locationX: 772, locationY: 467 },
    { locationX: 772, locationY: 462 },
  ],
  "10.3.12": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1192, locationY: 482 }, 
    { locationX: 1193, locationY: 473 },
    { locationX: 1193, locationY: 470 },
  ],
  "10.3.21": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 482 },
    { locationX: 1133, locationY: 482 }, 
    { locationX: 1135, locationY: 488 },
    { locationX: 1135, locationY: 494 },
  ],
  "10.3.7": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 665, locationY: 300 },
    { locationX: 665, locationY: 296 },
  ],
  "10.4.5": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 663, locationY: 377 },
    { locationX: 657, locationY: 380 },
    { locationX: 637, locationY: 379 },
  ],
  "10.4.12": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1192, locationY: 482 }, 
    { locationX: 1193, locationY: 473 },
    { locationX: 1193, locationY: 470 },
  ],
  "4.4.1": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 120, locationY: 510 },
    { locationX: 115, locationY: 515 },
    { locationX: 100, locationY: 515 },
  ],
  "10.4.8": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 655, locationY: 475 },
    { locationX: 772, locationY: 477 },
    { locationX: 772, locationY: 467 },
    { locationX: 772, locationY: 462 },
  ],
  "10.4.15": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1422, locationY: 485 }, 
    { locationX: 1425, locationY: 477 },
    { locationX: 1425, locationY: 473 },
  ],
  "10.4.19": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1278, locationY: 484 }, 
    { locationX: 1277, locationY: 492 },
    { locationX: 1277, locationY: 496 },
  ],
  "4.4.8": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 },
    { locationX: 420, locationY: 305 },
    { locationX: 428, locationY: 297 },
    { locationX: 428, locationY: 292 },
  ],  
  "10.4.9": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 660, locationY: 475 },
    { locationX: 802, locationY: 477 },
    { locationX: 802, locationY: 470 },
    { locationX: 802, locationY: 463 },
  ],
  "10.4.2": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 660, locationY: 475 },
    { locationX: 760, locationY: 477 },
    { locationX: 765, locationY: 482 },
    { locationX: 765, locationY: 489 },
  ],
  "10.4.6": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 665, locationY: 300 },
    { locationX: 665, locationY: 296 },
  ],
  "4.4.9": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 130, locationY: 490 },
    { locationX: 140, locationY: 487 },
    { locationX: 147, locationY: 488 },
  ],
  "10.4.21": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 482 },
    { locationX: 1133, locationY: 482 }, 
    { locationX: 1135, locationY: 488 },
    { locationX: 1135, locationY: 494 },
  ],
  "10.4.14": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1320, locationY: 483 }, 
    { locationX: 1323, locationY: 481 },
    { locationX: 1323, locationY: 471 },
  ],
  "10.4.4": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 450 },
    { locationX: 650, locationY: 450 },
    { locationX: 630, locationY: 450 },
  ],
  "10.4.13": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1295, locationY: 483 }, 
    { locationX: 1295, locationY: 475 },
    { locationX: 1295, locationY: 470 },
  ],
  "10.4.17": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1400, locationY: 485 }, 
    { locationX: 1010, locationY: 480 },
    { locationX: 1405, locationY: 493 },
    { locationX: 1405, locationY: 498 },
  ],
  "10.4.7": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 665, locationY: 300 },
    { locationX: 665, locationY: 296 },
  ],
  "10.4.3": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 487 },
  ],
  "10.4.11": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1160, locationY: 482 }, 
    { locationX: 1165, locationY: 478 },
    { locationX: 1165, locationY: 470 },
  ],
  "10.4.18": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1350, locationY: 485 }, 
    { locationX: 1355, locationY: 493 },
    { locationX: 1355, locationY: 498 },
  ],
  "4.4.2": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 145, locationY: 439 },
    { locationX: 135, locationY: 435 },
    { locationX: 122, locationY: 433 },
  ],
  "4.4.6": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 450, locationY: 305 },
    { locationX: 310, locationY: 303 },
    { locationX: 308, locationY: 300 },
    { locationX: 308, locationY: 292 },
  ], 
  "4.4.7": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 330, locationY: 303 },
    { locationX: 336, locationY: 295 },
    { locationX: 336, locationY: 291 },
  ],  
  "10.4.10": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1065, locationY: 480 }, 
    { locationX: 1065, locationY: 475 },
    { locationX: 1065, locationY: 468 },
  ],
  "10.4.16": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1458, locationY: 485 }, 
    { locationX: 1455, locationY: 477 },
    { locationX: 1455, locationY: 473 },
  ],
  "4.4.5": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 450, locationY: 305 },
    { locationX: 220, locationY: 302 },
    { locationX: 219, locationY: 296 },
    { locationX: 219, locationY: 290 },
  ], 
  "4.4.3": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 309 },
    { locationX: 186, locationY: 316 },
    { locationX: 174, locationY: 315 },
    { locationX: 163, locationY: 314 },
  ],
  "4.4.4": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 165, locationY: 298 },
  ],
  "10.4.1": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 663, locationY: 475 },
    { locationX: 802, locationY: 477 },
    { locationX: 805, locationY: 485 },
    { locationX: 805, locationY: 489 },
  ],
  "10.4.20": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 130, locationY: 490 },
    { locationX: 140, locationY: 487 },
    { locationX: 147, locationY: 488 },
  ],
  "4.5.1": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 120, locationY: 510 },
    { locationX: 115, locationY: 515 },
    { locationX: 100, locationY: 515 },
  ],
  "10.5.13": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1295, locationY: 483 }, 
    { locationX: 1295, locationY: 475 },
    { locationX: 1295, locationY: 470 },
  ],
  "10.5.17": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1400, locationY: 485 }, 
    { locationX: 1010, locationY: 480 },
    { locationX: 1405, locationY: 493 },
    { locationX: 1405, locationY: 498 },
  ],
  "4.5.7": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 330, locationY: 303 },
    { locationX: 336, locationY: 295 },
    { locationX: 336, locationY: 291 },
  ],  
  "10.5.7": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 665, locationY: 300 },
    { locationX: 665, locationY: 296 },
  ],
  "10.5.21": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 482 },
    { locationX: 1133, locationY: 482 }, 
    { locationX: 1135, locationY: 488 },
    { locationX: 1135, locationY: 494 },
  ],
  "4.5.4": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 165, locationY: 298 },
  ],
  "10.5.3": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 487 },
  ],
  "10.5.18": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1350, locationY: 485 }, 
    { locationX: 1355, locationY: 493 },
    { locationX: 1355, locationY: 498 },
  ],
  "4.5.9": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 130, locationY: 490 },
    { locationX: 140, locationY: 487 },
    { locationX: 147, locationY: 488 },
  ],
  "10.5.10": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1065, locationY: 480 }, 
    { locationX: 1065, locationY: 475 },
    { locationX: 1065, locationY: 468 },
  ],
  "10.5.2": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 660, locationY: 475 },
    { locationX: 760, locationY: 477 },
    { locationX: 765, locationY: 482 },
    { locationX: 765, locationY: 489 },
  ],
  "10.5.4": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 660, locationY: 450 },
    { locationX: 650, locationY: 450 },
    { locationX: 630, locationY: 450 },
  ],
  "4.5.5": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 450, locationY: 305 },
    { locationX: 220, locationY: 302 },
    { locationX: 219, locationY: 296 },
    { locationX: 219, locationY: 290 },
  ], 
  "4.5.3": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 309 },
    { locationX: 186, locationY: 316 },
    { locationX: 174, locationY: 315 },
    { locationX: 163, locationY: 314 },
  ],
  "4.5.2": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 452, locationY: 305 },
    { locationX: 190, locationY: 300 },
    { locationX: 187, locationY: 310 },
    { locationX: 145, locationY: 439 },
    { locationX: 135, locationY: 435 },
    { locationX: 122, locationY: 433 },
  ],
  "10.5.12": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1192, locationY: 482 }, 
    { locationX: 1193, locationY: 473 },
    { locationX: 1193, locationY: 470 },
  ],
  "10.5.14": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1320, locationY: 483 }, 
    { locationX: 1323, locationY: 481 },
    { locationX: 1323, locationY: 471 },
  ],
  "4.5.8": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 },
    { locationX: 420, locationY: 305 },
    { locationX: 428, locationY: 297 },
    { locationX: 428, locationY: 292 },
  ],  
  "10.5.11": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1022, locationY: 482 },
    { locationX: 1160, locationY: 482 }, 
    { locationX: 1165, locationY: 478 },
    { locationX: 1165, locationY: 470 },
  ],
  "10.5.19": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1278, locationY: 484 }, 
    { locationX: 1277, locationY: 492 },
    { locationX: 1277, locationY: 496 },
  ],
  "10.5.16": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1458, locationY: 485 }, 
    { locationX: 1455, locationY: 477 },
    { locationX: 1455, locationY: 473 },
  ],
  "10.5.1": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 657, locationY: 475 },
    { locationX: 807, locationY: 477 },
    { locationX: 805, locationY: 485 },
    { locationX: 805, locationY: 489 },
  ],
  "10.5.5": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 }, 
    { locationX: 667, locationY: 313 },
    { locationX: 663, locationY: 377 },
    { locationX: 657, locationY: 380 },
    { locationX: 637, locationY: 379 },
  ],
  "10.5.20": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1015, locationY: 480 },
    { locationX: 1220, locationY: 483 }, 
    { locationX: 1225, locationY: 490 },
    { locationX: 1225, locationY: 496 },
  ],
  "10.5.8": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 472 },
    { locationX: 655, locationY: 475 },
    { locationX: 772, locationY: 477 },
    { locationX: 772, locationY: 467 },
    { locationX: 772, locationY: 462 },
  ],
  "10.5.15": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1422, locationY: 485 }, 
    { locationX: 1425, locationY: 477 },
    { locationX: 1425, locationY: 473 },
  ],
  "10.5.9": [
    { locationX: 457, locationY: 293 }, 
    { locationX: 457, locationY: 298 }, 
    { locationX: 455, locationY: 305 }, 
    { locationX: 665, locationY: 307 },
    { locationX: 667, locationY: 313 },
    { locationX: 658, locationY: 470 },
    { locationX: 1015, locationY: 490 },
    { locationX: 1015, locationY: 520 },
    { locationX: 1007, locationY: 525 },
    { locationX: 885, locationY: 525 },
    { locationX: 880, locationY: 520 },
    { locationX: 880, locationY: 470 },
    { locationX: 870, locationY: 477 },
    { locationX: 660, locationY: 475 },
    { locationX: 1017, locationY: 480 },
    { locationX: 1430, locationY: 484 },
    { locationX: 1425, locationY: 478 },
    { locationX: 1425, locationY: 473 },
  ],
  "2.040": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 340 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 420, locationY: 343 }
  ],
  "2.048": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 545, locationY: 345 }
  ],
  "2.044": [
    { locationX: 696, locationY: 246 }, 
    { locationX: 600, locationY: 245 }, 
    { locationX: 600, locationY: 255 }, 
    { locationX: 600, locationY: 345 }, 
    { locationX: 595, locationY: 345 }, 
    { locationX: 480, locationY: 343 }
  ],
  "1.023": [
    { locationX: 696, locationY: 246 },  
    { locationX: 540, locationY: 243 }
  ],
  " 0.047": [
    { locationX: 550, locationY: 555 }, 
    { locationX: 535, locationY: 555 }, 
    { locationX: 535, locationY: 550 }, 
    { locationX: 519, locationY: 375 },
    { locationX: 525, locationY: 375 },
    { locationX: 535, locationY: 375 }
  ],
  "1.017": [
    { locationX: 696, locationY: 246 },  
    { locationX: 380, locationY: 240 }
  ],
  "1.025": [
    { locationX: 696, locationY: 246 },  
    { locationX: 595, locationY: 243 }
  ],
  "1.019": [
    { locationX: 696, locationY: 246 },  
    { locationX: 455, locationY: 241 }
  ],
}

export default SixthURLRoutes