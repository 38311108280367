import React, { useEffect, useRef } from "react";
import './Route.css';
import FirstURLRoutes from "./RoutesURL1";
import SecondURLRoutes from "./RoutesURL2";
import ThirdURLRoutes from "./RoutesURL3";
import FourthURLRoutes from "./RoutesURL4";
import FifthURLRoutes from "./RoutesURL5";
import SixthURLRoutes from "./RoutesURL6";

const IsDebugging = false;
const DebugVisualizeRoute = false;

function translateRoute(route, angleDegrees) {
    if (!route) {
        return route;
    }
    let routeCopy = copyRoute(route);
    const pivotPoint = angleDegrees % 180 === 0 ? { locationX: 385, locationY: 405 } : { locationX: 385, locationY: 385 }; //Note(aske): I'm not sure why these aren't = dimensions/2
    
    if (IsDebugging && routeCopy.length >= 2) {
        console.log("000d start (" + routeCopy[0].locationX + "," + routeCopy[0].locationY + ")");
        console.log("000d end (" + routeCopy[routeCopy.length - 1].locationX + "," + routeCopy[routeCopy.length - 1].locationY + ")");
    }
    const sinAngle = Math.sin(angleDegrees * 0.0174532925);
    const cosAngle = Math.cos(angleDegrees * 0.0174532925);

    //Rotate points around pivot point
    var debugStrOriginal = "original: ";
    var debugStrTranslated = "translated: ";
    var debugStrRotated = "rotated: ";
    var debugStrFinal = "final: ";
    for (var i = 0; i < routeCopy.length; i++) {
        var routePoint = routeCopy[i];
        debugStrOriginal += "(" + routePoint.locationX + "; " + routePoint.locationY + ") "; //NOTE (aske): unnecessary string concats typically isn't ideal for performance in production
        //translate back to origin
        routePoint.locationX -= pivotPoint.locationX;
        routePoint.locationY -= pivotPoint.locationY;

        debugStrTranslated += "(" + routePoint.locationX + "; " + routePoint.locationY + ") ";
        //rotate
        var rotatedX = routePoint.locationX * cosAngle - routePoint.locationY * sinAngle;
        var rotatedY = routePoint.locationX * sinAngle + routePoint.locationY * cosAngle;

        debugStrRotated += "(" + rotatedX + "; " + rotatedY + ") ";
        //translate back
        routePoint.locationX = rotatedX + pivotPoint.locationX;
        routePoint.locationY = rotatedY + pivotPoint.locationY;
        debugStrFinal += "(" + routePoint.locationX + "; " + routePoint.locationY + ") ";
        routeCopy[i] = routePoint;
    }
    if (IsDebugging && routeCopy.length >= 2) {
        console.log("270d start (" + routeCopy[0].locationX + "," + routeCopy[0].locationY + ")");
        console.log("270d end (" + routeCopy[routeCopy.length - 1].locationX + "," + routeCopy[routeCopy.length - 1].locationY + ")");
        console.log(debugStrOriginal);
        console.log(debugStrTranslated);
        console.log(debugStrRotated);
        console.log(debugStrFinal);
    }

    if (IsDebugging) {
        //console.log("route: " + route.length + ", finalRoute: " + routeCopy.length);
    }
    return { route: routeCopy, originalRoute: route };
}

function copyRoute(route) {
    let routeCopy = [];
    for (var i = 0; i < route.length; i++) {
        routeCopy[i] = {
            locationX: route[i].locationX, locationY: route[i].locationY
        };
    }
    return routeCopy;
}

const Route = props => {
    const canvasRef = useRef();

    function getRoute() {
        switch (props.screenNumber) {
            case 1:
                return translateRoute(FirstURLRoutes[props.destination], 270);
            case 2:
                return translateRoute(SecondURLRoutes[props.destination], 180);
            case 3:
                return translateRoute(ThirdURLRoutes[props.destination], 270);
            case 4:
                return translateRoute(FourthURLRoutes[props.destination], 180);
            case 5: {
                let tmpRoute5 = FifthURLRoutes[props.destination];
                return { route: tmpRoute5, originalRoute: tmpRoute5 };
            }
            case 6: {
                let tmpRoute6 = SixthURLRoutes[props.destination];
                return { route: tmpRoute6, originalRoute: tmpRoute6 };
            }
            default:
                return { route: [{ locationX: 0, locationY: 0 }], originalRoute: [{ locationX: 0, locationY: 0 }] };
        }
    }

    useEffect(() => {
        // config canvas
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d');
        ctx.strokeStyle = '#e82626';
        ctx.lineWidth = 5;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // get specific route
        const routeInfo = getRoute();

        if (routeInfo && routeInfo.route) {
            const route = routeInfo.route;
            const originalRoute = routeInfo.originalRoute;
            // draw route
            ctx.beginPath();
            ctx.setLineDash([8, 10]);

            for (let i = 0; i < route.length; i++) {
                //NOTE (aske): The goal is to avoid drawing the corner points,
                //so the odd index points are placed just before the turning point
                //and the even are placed just after the corner
                if (i % 2 === 1) {
                    ctx.lineTo(route[i].locationX, route[i].locationY);
                }
                ctx.moveTo(route[i].locationX, route[i].locationY);
            };
            ctx.stroke();
            if (IsDebugging && DebugVisualizeRoute) {
                for (let i = 0; i < route.length; i++) {
                    ctx.beginPath();
                    ctx.arc(route[i].locationX, route[i].locationY, 5 + i, 0, 2 * Math.PI, false);
                    ctx.fillStyle = 'blue';
                    ctx.fill();
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#0000ff';
                    ctx.stroke();
                }
                for (let i = 0; i < originalRoute.length; i++) { //Unrotated route points
                    ctx.beginPath();
                    ctx.arc(originalRoute[i].locationX, originalRoute[i].locationY, 5 + i - (originalRoute.length / 2), 0, 2 * Math.PI, false);
                    ctx.fillStyle = 'green';
                    ctx.fill();
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#00ff00';
                    ctx.stroke();
                }
                ctx.font = "24px serif";
                ctx.fillStyle = 'yellow';
                for (let i = 0; i < route.length / 2; i++) { //"1", "2" ... on rotated routepoints
                    ctx.fillText("" + (i + 1), route[i].locationX, route[i].locationY);
                }
            }
            if (IsDebugging) {
                //Draw pivot point
                ctx.beginPath();
                ctx.arc(385, 385, 10, 0, 2 * Math.PI, false);
                ctx.fillStyle = 'magenta';
                ctx.fill();
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#ff00ff';
                ctx.stroke();
            }
        }
    }, [props])

    return <canvas ref={canvasRef} {...props} height="1500" width="1500" />
}

export default Route;