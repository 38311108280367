import React from 'react';
import './Activities.css';
import {IActivityDTO} from "../../Domains/IActivityDTO";

interface Props{
    activities: IActivityDTO[];
}

interface State{
}

class Activities extends React.PureComponent<Props, State>{

    renderList = (items: any) =>{

        return(
            <div style={{backgroundColor:"#efefef", height:"100vh"}}>
            <div className="List-part List-part-activities">
                    <div className="Activity-head additional-activity-head">
                        <div className="Column-element head-element activity-head-element"></div>
                        <div className="Column-element head-element activity-head-element">Tid</div>
                        <div className="Column-element head-element activity-head-element">Aktivitet</div>
                        <div className="Column-element head-element activity-head-element">Lokale</div>
                        <div className="Column-element head-element activity-head-element">Etage</div>
                        <div className="Column-element head-element activity-head-element"></div>
                    </div>
                    {items.map(function (value:IActivityDTO, index:number){
                        return (
                            <div className="Activity additional-activity-height"
                                 style={index%2===0?{backgroundColor:"white"}:{backgroundColor:'#efefef'}}
                            >
                                <div className="Column-element"></div>
                                <div className="Column-element head-element">{value.time}</div>
                                <div className="Column-element additional-activity-element">{value.name}</div>
                                <div className="Column-element additional-activity-element">{value.location}</div>
                                <div className="Column-element additional-activity-element">{value.floor}</div>
                                <div className="Column-element vertical-align">
                                </div>
                            </div>)
                    })}
            </div>
            </div>
        )
    }


    render(){

        const activities = this.props.activities
        return (
            <div>
                {this.renderList(activities)}
            </div>
        )
    }
}

export default Activities;